<nav class="navbar navbar-toggleable-md navbar-light bg-light navbar-expand-sm">
  <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <img class="navbar-brand" src="./assets/images/CapitalMarkets.png" width="400" />
  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" [class.active]="url === '/doc'">
        <a class="nav-link" routerLink="doc">Create new document</a>
      </li>
      <li class="nav-item" [class.active]="url === '/update'">
        <a class="nav-link" routerLink="update">Update data in document</a>
      </li>
      <li class="nav-item" [class.active]="url === '/preview'">
        <a class="nav-link" routerLink="preview">Preview report data</a>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" style="color:#0275d8;" href="" id="navbarDropdownMenuLinks" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Useful links</a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLinks">
          <a class="dropdown-item" href="{{reonUrl}}" target="_blank">REON</a>
          <a class="dropdown-item" href="{{recoUrl}}" target="_blank">RECO</a>
          <a class="dropdown-item" href="{{companyEditUrl}}" target="_blank">Company Edit</a>
        </div>
      </li>
    </ul>
    <a class="nav-link" [routerLink]='["/authentication/logout"]' [state]='{ local: true, returnUrl: "/internalworkflows/" }' 
    title="Logout"><fa-icon [icon]="['fas','sign-out-alt']" size="2x"></fa-icon></a>
  </div>
</nav>

<div class="container">
  <div  class="row"  style="padding: 12px 0px;">
    <div> 
      <strong>Welcome to INWO, our tool for creating and updating Word documents with GROW data!</strong> INWO is a web page that shows you what 
      options are available for reports. These options are loaded and displayed when the page is loaded, which means that if you create new data 
      (like when you save a new Underwork report), you need to <strong>reload this page</strong> to see that data as an option. 
      In general: when in doubt, reload INWO! 
    </div>
    <br/>
    <div>
      Use the tabs at the top of the page to <strong>create</strong> a <strong>new</strong> Word document or <strong>update</strong> an <strong>existing</strong> 
      one (although note that you must fetch a new doc for each published report: you may not re-use a Word doc from the previous quarter).
    </div>

  </div>
</div>
<router-outlet></router-outlet>
