import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import {
  ApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import { AuthorizeService } from "api-authorization/authorize.service";

@Injectable({
  providedIn: "root",
})
export class AppInsightsLogger {
  appInsights: ApplicationInsights;
  userId: string = null;

  constructor(private authorizeService: AuthorizeService) {
    this.authorizeService.userSubject.subscribe((user) => {
      const userName = user ? user.name : null;
      if (userName !== null) this.appInsights.setAuthenticatedUserContext(userName);
    });

    const ai = (this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
      }
    }));

    ai.loadAppInsights();
    ai.trackPageView();

    const telemetryInitializer = (envelope: ITelemetryItem) => {
      //envelope.tags['ai.device.roleInstance'] = window.location.origin;
      envelope.tags["ai.device.roleName"] = environment.applicationName;
    };
    ai.addTelemetryInitializer(telemetryInitializer);

  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
