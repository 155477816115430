import * as tslib_1 from "tslib";
import { UserManager } from 'oidc-client';
import { BehaviorSubject, concat, from } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { ApplicationPaths } from './api-authorization.constants';
import { Logger } from '../app/_helpers';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../app/_helpers/logger";
export var AuthenticationResultStatus;
(function (AuthenticationResultStatus) {
    AuthenticationResultStatus[AuthenticationResultStatus["Success"] = 0] = "Success";
    AuthenticationResultStatus[AuthenticationResultStatus["Redirect"] = 1] = "Redirect";
    AuthenticationResultStatus[AuthenticationResultStatus["Fail"] = 2] = "Fail";
})(AuthenticationResultStatus || (AuthenticationResultStatus = {}));
export class AuthorizeService {
    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    constructor(logger) {
        this.logger = logger;
        this.popUpDisabled = true;
        this.userSubject = new BehaviorSubject(null);
        this.baseHref = `${window.location.origin}/`;
    }
    isAuthenticated() {
        return this.getUser().pipe(map(u => !!u));
    }
    getUser() {
        return concat(this.userSubject.pipe(take(1), filter(u => !!u)), this.getUserFromStorage().pipe(filter(u => !!u), tap(u => this.userSubject.next(u))), this.userSubject.asObservable());
    }
    getAccessToken() {
        return from(this.ensureUserManagerInitialized())
            .pipe(mergeMap(() => from(this.userManager.getUser())), map(user => user && user.access_token));
    }
    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    signIn(state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.ensureUserManagerInitialized();
            let user = null;
            try {
                user = yield this.userManager.signinSilent(this.createArguments());
                this.userSubject.next(user.profile);
                return this.success(state);
            }
            catch (silentError) {
                // User might not be authenticated, fallback to popup authentication
                this.logger.debug('Silent authentication error: ', silentError);
                try {
                    if (this.popUpDisabled) {
                        throw new Error('Popup disabled. Change \'authorize.service.ts:AuthorizeService.popupDisabled\' to false to enable it.');
                    }
                    user = yield this.userManager.signinPopup(this.createArguments());
                    this.userSubject.next(user.profile);
                    return this.success(state);
                }
                catch (popupError) {
                    if (popupError.message === 'Popup window closed') {
                        // The user explicitly cancelled the login action by closing an opened popup.
                        return this.error('The user closed the window.');
                    }
                    else if (!this.popUpDisabled) {
                        this.logger.debug('Popup authentication error: ', popupError);
                    }
                    // PopUps might be blocked by the user, fallback to redirect
                    try {
                        yield this.userManager.signinRedirect(this.createArguments(state));
                        return this.redirect();
                    }
                    catch (redirectError) {
                        this.logger.debug('Redirect authentication error: ', redirectError);
                        return this.error(redirectError);
                    }
                }
            }
        });
    }
    completeSignIn(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.ensureUserManagerInitialized();
                const user = yield this.userManager.signinCallback(url);
                this.userSubject.next(user && user.profile);
                return this.success(user && user.state);
            }
            catch (error) {
                this.logger.debug('There was an error signing in: ', error);
                return this.error('There was an error signing in.');
            }
        });
    }
    signOut(state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.popUpDisabled) {
                    throw new Error('Popup disabled. Change \'authorize.service.ts:AuthorizeService.popupDisabled\' to false to enable it.');
                }
                yield this.ensureUserManagerInitialized();
                yield this.userManager.signoutPopup(this.createArguments());
                this.userSubject.next(null);
                return this.success(state);
            }
            catch (popupSignOutError) {
                this.logger.debug('Popup signout error: ', popupSignOutError);
                try {
                    yield this.userManager.signoutRedirect(this.createArguments(state));
                    return this.redirect();
                }
                catch (redirectSignOutError) {
                    this.logger.debug('Redirect signout error: ', popupSignOutError);
                    return this.error(redirectSignOutError);
                }
            }
        });
    }
    completeSignOut(url) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.ensureUserManagerInitialized();
            try {
                const state = yield this.userManager.signoutCallback(url);
                this.userSubject.next(null);
                return this.success(state &&
                    state.hasOwnProperty('data') && state['data']);
            }
            catch (error) {
                this.logger.debug(`There was an error trying to log out '${error}'.`);
                return this.error(error);
            }
        });
    }
    createArguments(state) {
        return { useReplaceToNavigate: true, data: state };
    }
    error(message) {
        return { status: AuthenticationResultStatus.Fail, message };
    }
    success(state) {
        return { status: AuthenticationResultStatus.Success, state };
    }
    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }
    ensureUserManagerInitialized() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.userManager !== undefined) {
                return;
            }
            const clientConfigUrl = `${environment.baseUrlOidcConfig}${ApplicationPaths.ApiAuthorizationClientConfigurationUrl}`;
            const response = yield fetch(clientConfigUrl);
            if (!response.ok) {
                throw new Error(`Could not load settings for OIDC client`);
            }
            const settings = yield response.json();
            settings.automaticSilentRenew = true;
            settings.includeIdTokenInSilentRenew = true;
            settings.redirect_uri = `${this.baseHref}${ApplicationPaths.LoginCallback}`;
            settings.post_logout_redirect_uri = `${this.baseHref}${ApplicationPaths.LogOutCallback}`;
            this.logger.debug(settings);
            this.userManager = new UserManager(settings);
            this.userManager.events.addUserSignedOut(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.userManager.removeUser();
                this.userSubject.next(null);
            }));
        });
    }
    getUserFromStorage() {
        return from(this.ensureUserManagerInitialized())
            .pipe(mergeMap(() => this.userManager.getUser()), map(u => u && u.profile));
    }
}
AuthorizeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizeService_Factory() { return new AuthorizeService(i0.ɵɵinject(i1.Logger)); }, token: AuthorizeService, providedIn: "root" });
