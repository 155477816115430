
<div class="container">
  <div class="row" style="padding-top: 12px; margin-bottom: 60px;">
    <div class="col-md-8">
      <h3>1. Select company</h3>
      <div class="section" >
        <div class="input-group">
          <fa-icon [icon]="['fas','search']" [classes]="['input-group-addon']"></fa-icon>
          <input type="text" class="form-control" [(ngModel)]="searchString" placeholder="Find company...">
        </div>
        <div class="search-list" *ngIf="filteredCompanies.length > 0 && selectedCompany?.name != searchString">
          <ul *ngFor="let c of filteredCompanies" class="shb-coverage">
            <li [ngClass]="{'a-selected': c.isCovered}">
              <a (click)="selectSearchCompany(c)">{{c.name}}<span *ngIf="!c.hasReports"> (no reports)</span></a>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b>My companies</b>
            <div class="row" style="max-height: 200px; overflow-y:auto;">
              <div *ngFor="let c of companies" class="col-md-4" (click)="selectCompany(c)" style="cursor: pointer;"><b>{{c.role}}</b> {{c.companyName}}</div>
            </div>
          </div>
        </div>
      </div>

      <h3 style="padding-top: 16px;">2. Select GROW report(s)</h3>
      <report-selector [reports]="reports" [(selectedReport1)]="selectedReport1" [(selectedReport2)]="selectedReport2"></report-selector>

      <h3 style="padding-top: 16px;">3. Select data to preview</h3>
      <div class="row section">
        <div class="col-md-6">
          <select class="form-control" [(ngModel)]="selectedTemplates" *ngIf="templates">
            <option *ngFor="let t of templates" [ngValue]="t">{{t.Name}}</option>
          </select>
        </div>
        <div class="col-md-6">
          <select class="form-control" [(ngModel)]="selectedTemplate" *ngIf="selectedTemplates">
            <option *ngFor="let t of visibleTemplates()" [ngValue]="t">{{t.Description}} {{t.IsChart ? "(Chart)" :""}}</option>
          </select>
        </div>
      </div>

      <h3 style="padding-top: 16px;" >4. Select share price</h3>
      <div class="row section">
          <div class="col-md-7">
              <h5>Share price</h5>
              <select class="form-control" [(ngModel)]="sharePrice.selectedSharePrice">
                  <option *ngFor="let obj of sharePrice.filteredSharePrice" [ngValue]="obj"  [disabled]="!obj.active"> 
                      <div *ngIf="obj.active">{{obj.description}}: {{obj.price.toFixed(2)}} {{obj.currenct}} ({{obj.date.toSharePriceDateString(obj.showTime)}})</div>
                      <div *ngIf="!obj.active">{{obj.description}}</div>
                  </option>
              </select>
          </div>
          <div class="col-md-3" *ngIf="sharePrice.allowManualPrice()">
              <h5>Manual price</h5>
              <input type="text" class="form-control" [(ngModel)]="sharePrice.freeTextSharePrice" placeholder="Shareprice" (ngModelChange)="sharePrice.sharePriceFreeTextChange()" >
          </div>
      </div>

      <h3 style="padding-top: 16px;">5. Get preview</h3>
      <div class="section">
        <div class="alert alert-danger" role="alert" *ngIf="noCompanySelected || noReportReasonSelected || noReportTypeSelected || noSentToCompanySet">
          <div *ngIf="noCompanySelected"><strong>No company selected!</strong> You must select a company before creating a document.</div>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="sharePrice.noSharePriceSelected || sharePrice.invalidNumber">
          <div *ngIf="sharePrice.noSharePriceSelected"><strong>No shareprice selected!</strong> You must select a shareprice before creating a document.</div>
          <div *ngIf="sharePrice.invalidNumber"><strong>Not a valid number format!</strong></div>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
          {{errorMessage}}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="sharePriceErrorMessage">
          {{sharePriceErrorMessage}}
        </div>
        <div style="margin: 8px 0;">
          <button class="btn btn-primary" (click)="loadPreviewData()" [disabled]="isCreatingReport">Load data</button>
          <span *ngIf="isCreatingReport">
            <fa-icon [icon]="['fas','cog']" [styles]="{'vertical-align': 'middle', 'margin-left': '10px' }" size="2x" spin="true"></fa-icon>
          </span>
        </div>
      </div>

    </div>

    <div class="col-md-4" style="background-color: #f0f0f0;">
      <h3>Report summary</h3>
      <div *ngIf="!selectedCompany" style="font-style: italic;">(No company selected)</div>
      <div *ngIf="selectedCompany">
        <div>Company: <b style="font-size: 22px;">{{selectedCompany.name}}</b></div>
        <div>Share price: <b *ngIf="sharePrice.selectedSharePrice != null">{{sharePrice.selectedSharePrice.date.toSharePriceDateString(sharePrice.selectedSharePrice.showTime)}}: {{sharePrice.selectedSharePrice.currency}} {{sharePrice.selectedSharePrice.price?.toFixed(2)}}</b></div>
        <div *ngIf="sharePrice.selectedSharePrice != null" >Share price type: <b>{{sharePrice.selectedSharePrice.description}}</b></div>
        <report-information [report]="selectedReport1" [reportNumber]="1"></report-information>
        <report-information [report]="selectedReport2" [reportNumber]="2"></report-information>
      </div>
    </div>

    <div [innerHTML]="htmlTable | sanitizeHtmlPipe" class="col-md-12" style="padding-bottom:12px;"></div>

  </div>
</div>
