import { environment } from "environments/environment";
import { ApplicationInsights, } from "@microsoft/applicationinsights-web";
import { AuthorizeService } from "api-authorization/authorize.service";
import * as i0 from "@angular/core";
import * as i1 from "../../api-authorization/authorize.service";
export class AppInsightsLogger {
    constructor(authorizeService) {
        this.authorizeService = authorizeService;
        this.userId = null;
        this.authorizeService.userSubject.subscribe((user) => {
            const userName = user ? user.name : null;
            if (userName !== null)
                this.appInsights.setAuthenticatedUserContext(userName);
        });
        const ai = (this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                enableAutoRouteTracking: true,
            }
        }));
        ai.loadAppInsights();
        ai.trackPageView();
        const telemetryInitializer = (envelope) => {
            //envelope.tags['ai.device.roleInstance'] = window.location.origin;
            envelope.tags["ai.device.roleName"] = environment.applicationName;
        };
        ai.addTelemetryInitializer(telemetryInitializer);
    }
    logPageView(name, url) {
        // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url,
        });
    }
    logEvent(name, properties) {
        this.appInsights.trackEvent({ name: name }, properties);
    }
    logMetric(name, average, properties) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
    logException(exception, severityLevel) {
        this.appInsights.trackException({
            exception: exception,
            severityLevel: severityLevel,
        });
    }
    logTrace(message, properties) {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}
AppInsightsLogger.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInsightsLogger_Factory() { return new AppInsightsLogger(i0.ɵɵinject(i1.AuthorizeService)); }, token: AppInsightsLogger, providedIn: "root" });
