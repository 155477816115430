import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportDisplay } from './report-selector.model';

@Component({
	selector: 'report-selector',
	templateUrl: './report-selector.component.html',
	styleUrls: ['./report-selector.component.css']
})
export class ReportSelectorComponent implements OnInit
{
	@Input() reports: ReportDisplay[];
	@Input() selectedReport1: ReportDisplay;
	@Input() selectedReport2: ReportDisplay;
  	@Input() isCredit: boolean;

	@Output() selectedReport1Change = new EventEmitter<App.WebApi.ReportDisplay>();
	@Output() selectedReport2Change = new EventEmitter<App.WebApi.ReportDisplay>();

	constructor() { }

	ngOnInit()
	{
	}

	selectReport(r: App.WebApi.ReportDisplay)
	{
		// Toggle the selected report
		r.isActive = !r.isActive;

		// Check how many reports are active now
		let activeReports: number[] = [];

		this.reports.forEach(element => {
			if (element.isActive)
			{
				activeReports.push(element.$id);
			}
		});

		// Do we need to deactivate?
		if (activeReports.length > 2)
		{
			// Deactivate the one closest to the newly selected
			// Newly selected is lower or higher index than the currently selected
			if ((Number(r.$id) > Number(activeReports[1])) || (Number(r.$id) < Number(activeReports[1]))) 
			{
				this.reports[activeReports[1] - 1].isActive = false;
			}
			else // Newly selected is between currently selected
			{
				this.reports[activeReports[0] - 1].isActive = false;
			}
		}

		// Return the selected report(s)
		this.selectedReport1 = null;
		this.selectedReport2 = null;

		// .some -> like forEach but breaks on return true;
		this.reports.some(r =>
		{
			if (r.isActive)
				if (this.selectedReport1 == null)
				{
					this.selectedReport1 = r;
				}
				else
				{
					this.selectedReport2 = r;
					return true;
				}
		});

		this.selectedReport1Change.emit(this.selectedReport1);
		this.selectedReport2Change.emit(this.selectedReport2);
	}

}

