export { }

declare global
{
	interface Date
	{
		toSweString(): string;
		toSharePriceDateString(time: boolean): string;
	}
}
Date.prototype.toSweString = function(): string
{
	var MM = this.getMonth() + 1; // getMonth() is zero-based
	if (MM < 10) MM = "0" + MM;
	var dd = this.getDate();
	if (dd < 10) dd = "0" + dd;
	var hh = this.getHours();
	if (hh < 10) hh = "0" + hh;
	var mm = this.getMinutes();
	if (mm < 10) mm = "0" + mm;
	var ss = this.getSeconds();
	if (ss < 10) ss = "0" + ss;

	//return this.getFullYear() + "-" + MM + "-" + dd + " " + hh + ":" + mm + ":" + ss;
	return `${this.getFullYear()}-${MM}-${dd} ${hh}:${mm}:${ss}`;
};

Date.prototype.toSharePriceDateString = function(time: boolean): string
{
	var monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	var dd = this.getDate();
	if (dd < 10) dd = "0" + dd;
	var hh = this.getHours();
	if (hh < 10) hh = "0" + hh;
	var mm = this.getMinutes();
	if (mm < 10) mm = "0" + mm;

	var ret = monthNamesShort[this.getMonth()] + " " + dd  
	if(time)
	{
		ret += " " + hh + ":" + mm + " CET";
	}
	return ret;
};

