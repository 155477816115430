<div style="margin: 8px 0;">
  <div class="container">
    <div class="row" style="padding-top: 12px; margin-bottom: 60px;">
      <div class="col-md-8">
        <h3>1. Select company</h3>
        <div class="section">
          <div class="input-group">
            <fa-icon [icon]="['fas','search']" [classes]="['input-group-addon']"></fa-icon>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="searchString"
              placeholder="Find company..."
            />
          </div>
          <div
            class="search-list"
            *ngIf="
              filteredCompanies.length > 0 &&
              selectedCompany?.name != searchString
            "
          >
            <ul *ngFor="let c of filteredCompanies" class="shb-coverage">
              <li [ngClass]="{ 'a-selected': c.isCovered }">
                <a (click)="selectSearchCompany(c)"
                  >{{ c.name
                  }}<span *ngIf="!c.hasReports"> (no reports)</span></a
                >
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b>My companies</b>
              <div class="row" style="max-height: 200px; overflow-y:auto;">
                <div
                  *ngFor="let c of companies"
                  class="col-md-4"
                  (click)="selectCompany(c)"
                  style="cursor: pointer;"
                >
                  <b>{{ c.role }}</b> {{ c.companyName }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <h3 style="padding-top: 16px;">
          2. Select GROW report(s)<span
            style="font-size:1rem; font-weight:normal;color:black; padding-left:12px;"
            ><label
              ><input
                type="radio"
                name="cType"
                value="Equity"
                [(ngModel)]="cType"
                (change)="cTypeChanged($event)"
              />
              Equity</label
            >
            <label
              ><input
                type="radio"
                name="cType"
                value="Credit"
                [(ngModel)]="cType"
                (change)="cTypeChanged($event)"
              />
              Credit</label
            ></span
          >
        </h3>
        <report-selector
          [reports]="reports"
          [(selectedReport1)]="selectedReport1"
          [(selectedReport2)]="selectedReport2"
          [isCredit]="isCredit"
        ></report-selector>

        <h3 style="padding-top: 16px;">3. Select publication type</h3>
        <div class="row section">
          <div class="col-md-6">
            <h5>Publication type</h5>
            <select
              class="form-control"
              [(ngModel)]="selectedReportType"
              *ngIf="startOptions"
              (change)="selectedReportTypeChanged($event)"
            >
              <option *ngFor="let obj of filteredReportTypes" [ngValue]="obj">{{
                obj.Name
              }}</option>
            </select>
          </div>
          <div class="col-md-6"
            *ngIf="!isCredit"
          >
            <h5>Reason for writing</h5>
            <select
              class="form-control"
              [(ngModel)]="selectedReportReason"
              *ngIf="startOptions"
              (change)="selectedReasonChanged()"
            >
              <option
                *ngFor="let obj of filteredReportReasons"
                [ngValue]="obj"
                >{{ obj.Name }}</option
              >
            </select>
          </div>
          <div
            class="col-md-12"
            *ngIf="showSelectFrontPage"
            style="margin-top:8px;"
          >
            <h5>Start page</h5>
            <span
              *ngFor="let obj of startOptions.FrontPageUrls"
              class="frontPageSpan"
              ><img
                src="{{ reonApiBaseUrl + obj.Name }}"
                alt="{{ obj.Value }}"
                [ngClass]="{ Selected: obj.isSelected }"
                (click)="selectedFrontPage(obj)"
            /></span>
          </div>
          <div class="col-md-12">
            <table style="line-height: 1em; margin-top: 8px;">
              <tr>
                <td style="vertical-align: top; padding-top: 10px;">
                  This report has been/will be sent to the Company prior to
                  publication to check the accuracy of facts?
                </td>
                <td style="width: 80px;  padding-top: 10px;">
                  <div>
                    <label
                      ><input
                        type="radio"
                        [(ngModel)]="sentToCompany"
                        name="sentToCompany"
                        value="true"
                        (change)="sentToCompanyChanged()"
                      />
                      Yes</label
                    >
                  </div>
                  <div>
                    <label
                      ><input
                        type="radio"
                        [(ngModel)]="sentToCompany"
                        name="sentToCompany"
                        value="false"
                        (change)="sentToCompanyChanged()"
                      />
                      No</label
                    >
                  </div>
                </td>
              </tr>
              <tr
                *ngIf="isCredit"
                style="border-bottom:solid 1px gray; border-top:solid 1px gray;"
              >
                <td style="vertical-align: top; padding-top: 10px;">
                  The preparation and and publishing of this report are related
                  to Handelsbanken Capital Markets having a mandate to issue
                  bonds for this company?
                </td>
                <td style="width: 80px; padding-top: 10px;">
                  <div>
                    <label
                      ><input
                        type="radio"
                        [(ngModel)]="mandateToIssueBonds"
                        name="mandateToIssueBonds"
                        value="true"
                        (change)="mandateToIssueBondsChanged()"
                      />
                      Yes</label
                    >
                  </div>
                  <div>
                    <label
                      ><input
                        type="radio"
                        [(ngModel)]="mandateToIssueBonds"
                        name="mandateToIssueBonds"
                        value="false"
                        (change)="mandateToIssueBondsChanged()"
                      />
                      No</label
                    >
                  </div>
                </td>
              </tr>
              <tr *ngIf="isCredit" style="border-top:solid 1px gray;">
                <td style="vertical-align: top;  padding-top: 10px;">
                  This report has been prepared on an ad-hoc basis, which means
                  that Handelsbanken Capital Markets is not taking up full
                  coverage of the subject company as a creditor?
                </td>
                <td style="width: 80px;  padding-top: 10px;">
                  <div>
                    <label
                      ><input
                        type="radio"
                        [(ngModel)]="adHocReport"
                        name="adHocReport"
                        value="true"
                        (change)="adHocReportChanged()"
                      />
                      Yes</label
                    >
                  </div>
                  <div>
                    <label
                      ><input
                        type="radio"
                        [(ngModel)]="adHocReport"
                        name="adHocReport"
                        value="false"
                        (change)="adHocReportChanged()"
                      />
                      No</label
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div style="display:none;">
          <h3 style="padding-top: 16px;">
            3.2. Select Template Engine (optional)
          </h3>
          <div class="row section">
            <div class="col-md-6">
              <h5>Template Engine</h5>
              <select
                class="form-control"
                [(ngModel)]="selectedTemplateEngine"
                *ngIf="startOptions"
              >
                <option
                  *ngFor="let obj of startOptions.TemplateCreationEngines"
                  [ngValue]="obj"
                  >{{ obj.Name }}</option
                >
              </select>
            </div>
          </div>
        </div>

        <h3 style="padding-top: 16px;" *ngIf="!isCredit">
          4. Select share price
        </h3>
        <div class="row section" *ngIf="!isCredit">
          <div class="col-md-7">
            <h5>Share price</h5>
            <select
              class="form-control"
              [(ngModel)]="sharePrice.selectedSharePrice"
              (change)="sharePriceSelectChange()"
            >
              <option
                *ngFor="let obj of sharePrice.filteredSharePrice"
                [ngValue]="obj"
                [disabled]="!obj.active"
              >
                <div *ngIf="obj.active">
                  {{ obj.description }}: {{ obj.price.toFixed(2) }}
                  {{ obj.currenct }} ({{
                    obj.date.toSharePriceDateString(obj.showTime)
                  }})
                </div>
                <div *ngIf="!obj.active">{{ obj.description }}</div>
              </option>
            </select>
          </div>
          <div class="col-md-3" *ngIf="sharePrice.isManualPrice() && sharePrice.allowManualPrice()">
            <h5>Manual price</h5>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="sharePrice.freeTextSharePrice"
              placeholder="Shareprice"
              (ngModelChange)="sharePrice.sharePriceFreeTextChange()"
            />
          </div>
        </div>

        <h3 style="padding-top: 16px;" *ngIf="!isCredit">5. Create document</h3>
        <h3 style="padding-top: 16px;" *ngIf="isCredit">4. Create document</h3>
        <div class="section">
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="
              noCompanySelected ||
              noReportReasonSelected ||
              noReportTypeSelected ||
              noSentToCompanySet ||
              noMandateToIssueBondsSet ||
              noAdHocReportSet ||
              sharePrice.noSharePriceSelected ||
              sharePrice.invalidNumber
            "
          >
            <div *ngIf="noCompanySelected">
              <strong>No company selected!</strong> You must select a company
              before creating a document.
            </div>
            <div *ngIf="noReportTypeSelected">
              <strong>No Publication type selected!</strong> You must select a
              publication type before creating a document.
            </div>
            <div *ngIf="noReportReasonSelected">
              <strong>No Reason for writing selected!</strong> You must select a
              reason for writing before creating a document.
            </div>
            <div *ngIf="noSentToCompanySet">
              <strong>Fact-checked by company!</strong> You must select yes or
              no.
            </div>
            <div *ngIf="noMandateToIssueBondsSet">
              <strong>Mandate to issue bonds!</strong> You must select yes or
              no.
            </div>
            <div *ngIf="noAdHocReportSet">
              <strong>Ad-hoc report!</strong> You must select yes or no.
            </div>
            <div *ngIf="sharePrice.noSharePriceSelected">
              <strong>No shareprice selected!</strong> You must select a
              shareprice before creating a document.
            </div>
            <div *ngIf="sharePrice.invalidNumber">
              <strong>Not a valid number format!</strong>
            </div>
          </div>
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            *ngIf="sharePriceErrorMessage"
          >
            {{ sharePriceErrorMessage }}
          </div>
          <div style="margin: 8px 0;">
            <button
              class="btn btn-primary"
              (click)="createReport()"
              [disabled]="isCreatingReport"
              *ngIf="!isCreatingReport"
            >
              Create document
            </button>
          </div>
          <div>
            <span *ngIf="isCreatingReport" style="font-style:italic;">
              <fa-icon [icon]="['fas','cog']" [styles]="{'vertical-align': 'middle'}" size="2x" spin="true"></fa-icon>
              &nbsp; {{ oneliner }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-4" style="background-color: #f0f0f0;">
        <h3>Report summary</h3>
        <div *ngIf="!selectedCompany" style="font-style: italic;">
          (No company selected)
        </div>
        <div *ngIf="selectedCompany">
          <div>
            Company: <b style="font-size: 22px;">{{ selectedCompany.name }}</b>
          </div>
          <div>
            Share price:
            <b *ngIf="sharePrice.selectedSharePrice != null"
              >{{
                sharePrice.selectedSharePrice.date.toSharePriceDateString(
                  sharePrice.selectedSharePrice.showTime
                )
              }}: {{ sharePrice.selectedSharePrice.currency }}
              {{ sharePrice.selectedSharePrice.price?.toFixed(2) }}</b
            >
          </div>
          <div *ngIf="sharePrice.selectedSharePrice != null">
            Share price type:
            <b>{{ sharePrice.selectedSharePrice.description }}</b>
          </div>
          <div>
            Company type: <b>{{ selectedCompany.companyType }}</b>
          </div>
          <report-information
            [report]="selectedReport1"
            [reportNumber]="1"
            [isCredit]="isCredit"
          ></report-information>
          <report-information
            [report]="selectedReport2"
            [reportNumber]="2"
            [isCredit]="isCredit"
          ></report-information>
          <div style="margin-top: 8px;">
            Report Type:
            <b>{{
              selectedReportType ? selectedReportType.Name : "Not selected"
            }}</b>
          </div>
          <div style="margin-top: 8px; margin-bottom: 8px;">
            Report Reason:
            <b>{{
              selectedReportReason ? selectedReportReason.Name : "Not selected"
            }}</b>
          </div>
          <div *ngIf="isCredit">
            Mandate to issue bonds:
            <b>{{
              mandateToIssueBonds == null
                ? "Not selected"
                : mandateToIssueBonds == "true"
                ? "Yes"
                : "No"
            }}</b>
          </div>
          <div>
            Fact-checked by company:
            <b>{{
              sentToCompany == null
                ? "Not selected"
                : sentToCompany == "true"
                ? "Yes"
                : "No"
            }}</b>
          </div>
          <div *ngIf="isCredit">
            Ad-hoc report:
            <b>{{
              adHocReport == null
                ? "Not selected"
                : adHocReport == "true"
                ? "Yes"
                : "No"
            }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
