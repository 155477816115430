import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxLoggerLevel, LoggerModule } from 'ngx-logger';
import { Logger } from './_helpers';
import { routing } from './app.routing';
import { AuthorizeInterceptor } from '../api-authorization/authorize.interceptor';
import { ApiAuthorizationModule } from '../api-authorization/api-authorization.module';
import { UnauthorizedInterceptor } from "../api-authorization/unauthorized.interceptor";

import { AppComponent } from "./app.component";
import {
  GlobalDataService, 
  SharePriceService, 
  WebApiService
} from "./services";
import {
  GenerateDocumentComponent,
  UpdateDocumentComponent,
  ReportInformationComponent,
  ReportSelectorComponent,
  DataPreviewComponent
} from "./components";

import { SanitizeHtmlPipePipe } from "./sanitize-html-pipe.pipe";
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { environment } from "../environments/environment";
import { ErrorHandlerService } from './common/error-handler-service';

@NgModule({
  declarations: [
    AppComponent,
    GenerateDocumentComponent,
    UpdateDocumentComponent,
    ReportInformationComponent,
    ReportSelectorComponent,
    DataPreviewComponent,
    SanitizeHtmlPipePipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    FontAwesomeModule,
    HttpClientModule,
    ApiAuthorizationModule,
    routing,
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
      disableConsoleLogging: environment.production,
    })
  ],
  providers: [
    Logger,
    WebApiService,
    GlobalDataService,
    SharePriceService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }

}
