export const environment = {
  production: true,
  applicationName: "#{Release.EnvironmentName}#-internalworkflows-frontend",
  baseUrlOidcConfig: "#{Ng.Authority}#",
  baseUrlGrowApi: "#{Ng.BaseUrlGrowApi}#",
  baseUrlGrowCompanyEdit: "#{Ng.BaseUrlGrowCompanyEdit}#",
  baseUrlReonApi: "#{Ng.BaseUrlReonApi}#",
  baseUrlReon: "#{Ng.BaseUrlReon}#",
  recoWebUrl: "#{Ng.recoWebUrl}#",
  appInsights: {
    instrumentationKey: '#{ApplicationInsights.InstrumentationKey}#'
  }
}
