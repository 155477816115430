import { Component } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";

import { GlobalDataService } from "./services/global-data.service";
import { ApplicationPaths } from '../api-authorization/api-authorization.constants';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'environments/environment';

@Component({

  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent {
  title = "app works!Aaa";
  url = "/";
  applicationPaths = ApplicationPaths;

  constructor(
    router: Router,
    private globalData: GlobalDataService
  ) {
    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.url = (e as NavigationStart).url;
        if (this.url == "/") this.url = "/doc";
      }
    });
  }

  get recoUrl() {
    return environment.recoWebUrl;
  }

  get companyEditUrl() {
    return `${environment.baseUrlGrowCompanyEdit}edit`;
  }

  get reonUrl(): string {

    if (this.globalData.selectedCompanyId) {
      return `${environment.baseUrlReon}company/${this.globalData.selectedCompanyId}/overview`;
    }

    return `${environment.baseUrlReon}start`;
  }
}
