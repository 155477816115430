import { Injectable } from '@angular/core';
import { CompanySharePrice, SharePrice, SharePriceType } from "./web-api.models";
import { WebApiService } from "./web-api.service";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SharePriceService 
{

  public selectedCompanySharePrice: CompanySharePrice;
  public selectedSharePrice: SharePrice;
  public allSharePrices: SharePrice[] = null;
  public freeTextSharePrice: string = null;

  public noSharePriceSelected: boolean;
  public invalidNumber: boolean = false;

  private errorMessageSource: BehaviorSubject<string>;
  public errorMessage: Observable<string>;
  
  constructor(public webApi: WebApiService) 
  { 
    this.errorMessageSource = new BehaviorSubject<string>(null);
    this.errorMessage = this.errorMessageSource.asObservable();
    this.selectedCompanySharePrice = null;
  }

  private initSharePrices(){
    this.allSharePrices = new Array<SharePrice>(4)
    this.allSharePrices[SharePriceType.Manual] = new SharePrice(SharePriceType.Manual);
    this.allSharePrices[SharePriceType.Manual].date = new Date();
    this.allSharePrices[SharePriceType.Official] = new SharePrice(SharePriceType.Official);
    this.allSharePrices[SharePriceType.Last] = new SharePrice(SharePriceType.Last);
    this.allSharePrices[SharePriceType.Preliminary] = new SharePrice(SharePriceType.Preliminary);
  }

  getSharePrice(companyId: number)
  {
    this.selectedSharePrice = null;
    this.selectedCompanySharePrice = null;
    this.freeTextSharePrice = null;
    this.initSharePrices();

    if (companyId == 0)
      return;

    this.webApi.getSharePrices(companyId)
      .subscribe(
        (sharePrices) => 
        {
            this.selectedCompanySharePrice = sharePrices;
            console.log("Shareprices" , sharePrices);
            if(sharePrices.ManualPrice){
              this.freeTextSharePrice = sharePrices.LastPrice ? sharePrices.LastPrice.toString(): null;
              this.sharePriceFreeTextChange();
            }
            if (sharePrices.OfficialClosePrice != null){
              this.selectedSharePrice = this.allSharePrices[SharePriceType.Official];

              this.allSharePrices[SharePriceType.Official].sourceType = sharePrices.SourceType;
              this.allSharePrices[SharePriceType.Official].price = sharePrices.OfficialClosePrice;
              this.allSharePrices[SharePriceType.Official].date = sharePrices.OfficialCloseDate;
            }
        },
        (error) => this.errorMessageSource.next(error)
      );
  }

  sharePriceFreeTextChange(){
    this.allSharePrices[SharePriceType.Manual].price = this.parsDecimalString(this.freeTextSharePrice);
    this.allSharePrices[SharePriceType.Manual].date = new Date();
    this.selectedSharePrice = this.allSharePrices[SharePriceType.Manual];
  }

  get filteredSharePrice(): SharePrice[]
  {
    if (this.selectedCompanySharePrice != null){
      this.allSharePrices.forEach(
        (x) => { 
          x.currency = this.selectedCompanySharePrice.Currency;
          x.date = x.type == SharePriceType.Manual ? x.date : null; 
          x.price = null; 
          x.active = false;
          x.sourceType = this.selectedCompanySharePrice.SourceType;
        } 
      );
      if(this.selectedCompanySharePrice.OfficialClosePrice != null)
      {
        this.allSharePrices[SharePriceType.Official].price = this.selectedCompanySharePrice.OfficialClosePrice;
        this.allSharePrices[SharePriceType.Official].date = this.selectedCompanySharePrice.OfficialCloseDate;
        this.allSharePrices[SharePriceType.Official].active = true;
      }
      if (this.selectedCompanySharePrice.LastPrice != null  && this.selectedCompanySharePrice.LastDate > this.selectedCompanySharePrice.OfficialCloseDate)
      {
        this.allSharePrices[SharePriceType.Last].price = this.selectedCompanySharePrice.LastPrice;
        this.allSharePrices[SharePriceType.Last].date = this.selectedCompanySharePrice.LastDate;
        this.allSharePrices[SharePriceType.Last].active = true; 
      }
      if(this.selectedCompanySharePrice.TodayClosePrice != null)
      {
        this.allSharePrices[SharePriceType.Preliminary].price = this.selectedCompanySharePrice.TodayClosePrice;
        this.allSharePrices[SharePriceType.Preliminary].date = this.selectedCompanySharePrice.TodayCloseTime;
        this.allSharePrices[SharePriceType.Preliminary].active = true; 
      }
      if (!this.allSharePrices[SharePriceType.Preliminary].active || this.selectedCompanySharePrice.ManualPrice==true)
      {
        this.allSharePrices[SharePriceType.Manual].price = this.parsDecimalString(this.freeTextSharePrice);
        this.allSharePrices[SharePriceType.Manual].active = true;
      }
    }

    return this.allSharePrices;
  }

  saveIntradayPrice(companyId: number): Observable<any>
  {
    
    let data: App.WebApi.SaveIntradayPrice = {
      CompanyId: companyId,
      LastPrice: this.selectedSharePrice.price,
      LastDateTime: new Date(this.selectedSharePrice.date.getUTCFullYear(),
      this.selectedSharePrice.date.getUTCMonth(),this.selectedSharePrice.date.getUTCDate(),this.selectedSharePrice.date.getUTCHours(),
      this.selectedSharePrice.date.getUTCMinutes(),this.selectedSharePrice.date.getUTCSeconds()).toSweString(),
      Currency: this.selectedSharePrice.currency, 
      SourceType: this.selectedSharePrice.sourceType
    };

    return this.webApi.saveIntradayPrice(data);
  }

  updateIntradayPriceFormulas(companyId: number) : Observable<any>
  {

      let data: App.WebApi.SaveIntradayPrice = {
          CompanyId: companyId,
          LastPrice: this.selectedSharePrice.price,
          LastDateTime: new Date(this.selectedSharePrice.date.getUTCFullYear(),
          this.selectedSharePrice.date.getUTCMonth(),this.selectedSharePrice.date.getUTCDate(),this.selectedSharePrice.date.getUTCHours(),
          this.selectedSharePrice.date.getUTCMinutes(),this.selectedSharePrice.date.getUTCSeconds()).toSweString(),
          Currency: this.selectedSharePrice.currency,
          SourceType: this.selectedSharePrice.sourceType
      };

      return this.webApi.updateIntradayPriceFormulas(data);
  }

  allowManualPrice(){
    return this.allSharePrices[SharePriceType.Manual].active;
  }

  parsDecimalString(s:string) : number
  {
    var regex = /^\d*(\.\d*)?$/;
    this.invalidNumber = false;

    if(s == null){ return 0; }
    else if(regex.test(s.trim())){ return Number(s); }

    this.invalidNumber = true;
    return 0;
  }

  hasSelectedPrice(credit: boolean) 
  {
    this.noSharePriceSelected = !credit && (this.selectedSharePrice == null || this.selectedSharePrice.price == null)
    return this.noSharePriceSelected;
  }

  setDefault()
  {
    if (this.selectedCompanySharePrice != null){
      if (this.selectedCompanySharePrice.OfficialClosePrice != null){
      this.selectedSharePrice = this.allSharePrices[SharePriceType.Official];
    }}
  }

  isManualPrice(){
    if(this.selectedSharePrice == null)
    {
      return false;
    }
    return this.selectedSharePrice.type ==  SharePriceType.Manual;
  }
  isLastPrice() {
      if (this.selectedSharePrice == null) {
          return false;
      }
      return this.selectedSharePrice.type == SharePriceType.Last;
  }
  isPreliminaryPrice() {
      if (this.selectedSharePrice == null) {
          return false;
      }
      return this.selectedSharePrice.type == SharePriceType.Preliminary;
  }

}
