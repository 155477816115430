export class CompanyRole
{
    role:string;
    companyId:number;
    companyName:string;
}

export class CompanyData
{
  id: number;
  name: string;
  lname: string;
  isCovered: boolean;
  price?: number;
  priceDate?: string;
  currency: string;
  hasReports?: boolean;
  nameAlias?: string[];
  companyType?: string;
  isEquity: boolean;
  isCredit: boolean;
}

export class FileData
{
  fileName:string;
	blob: Blob;
	errorMsg?: string;
}
export class CompanySharePrice
{   
  CompanyId: number;
  OfficialClosePrice?: number;
  OfficialCloseDate?: Date;
  LastPrice?: number;
  LastDate?: Date;
  ManualPrice?: boolean;
  TodayClosePrice?: number;
  TodayCloseTime?: Date;
  Currency: string; 
  SourceType: number;
}

export class SharePrice
{
  description: string; 
  type: SharePriceType;
  showTime: boolean;
  date: Date; 
  currency: string;
  price?: number;
  active: boolean;
  sourceType: number;

  constructor (type: SharePriceType) {
    this.type = type;
    this.date = null; 
    this.currency = ""; 
    this.price = null;
    this.active = false;
    this.sourceType = 0;

    switch(this.type) {
      case SharePriceType.Official: this.description = "Official closing price"; this.showTime = false; break;
      case SharePriceType.Manual: this.description = "Manual price"; this.showTime = true; break;
      case SharePriceType.Last: this.description = "Last intraday price"; this.showTime = true; break;
      case SharePriceType.Preliminary: this.description = "Today's preliminary closing price"; this.showTime = false; break;
    }
  }

  
}

export enum SharePriceType {
  Official = 0,
  Manual,
  Last,
  Preliminary,
}
