<div class="section">
  <div style="overflow: auto; max-height: 300px;">
    <div *ngIf="reports == null || reports.length == 0" style="font-style: italic">(No reports for this company)</div>
    <table class="table table-sm" *ngIf="reports != null && reports.length > 0">
      <tr>
        <th>Status</th>
        <th>Est period</th>
        <th>Analyst</th>
        <th *ngIf="isCredit">Currency</th>
        <th *ngIf="!isCredit">Target&nbsp;price</th>
        <th *ngIf="!isCredit">Rec. (3m)</th>
        <th *ngIf="!isCredit">Rec. (3y)</th>
        <th>Published*</th>
        <!--<th>Created</th><th>KeyRatioId</th><th>KeyRatioName</th><th>DCF</th>-->
      </tr>
      <tr *ngFor="let r of reports" (click)="selectReport(r)" [ngClass]="{'activeRow': r.isActive}" style="cursor: pointer;">
        <td>{{r.ReportStatus}}</td>
        <td>{{r.ReportPeriodQuartal}} {{r.ReportPeriodYear}}</td>
        <td>{{r.ReportAnalyst}}</td>
        <td *ngIf="isCredit">{{r.Currency}}</td>
        <td *ngIf="!isCredit">{{r.SharePriceCurrency}} {{r.TargetPrice?.toFixed(2)}}</td>
        <td *ngIf="!isCredit">{{r.Rating}}</td>
        <td *ngIf="!isCredit">{{r.RatingLongTerm}}</td>
        <td>{{r.PublishedDate?.substr(0,10)}}</td>
        <!--<td>{{r.CreatedDate?.substr(0,10)}}</td><td>{{r.KeyRatioId}}</td><td>{{r.KeyRatioName}}</td><td>{{r.DCF}}</td>-->
      </tr>
    </table>
  </div>
  <span *ngIf="reports != null && reports.length > 0" style="font-style: italic; font-size:10px;">* For unpublished reports the creation date is shown</span>
</div>
