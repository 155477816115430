<div style="margin-top: 8px;">
  <span style="text-decoration: underline;">Report {{reportNumber}}:</span>
  <fa-icon [icon]="['fas','edit']" [classes]="['activeColor']" *ngIf="hasChanged" title="{{hasChanged}}"></fa-icon>
</div>
<div style="padding-left: 8px;">
  <div *ngIf="!report" style="font-style: italic;">(No report selected)</div>
  <div *ngIf="report">
    <div>Status:
      <b>{{report.ReportStatus}}</b>
    </div>
    <div>Estimated period:
      <b>{{report.ReportPeriodQuartal}} {{report.ReportPeriodYear}}</b>
    </div>
    <div>Analyst:
      <b>{{report.ReportAnalyst}}</b>
    </div>
    <div *ngIf="!isCredit">Target price:
      <b>{{report.SharePriceCurrency}} {{report.TargetPrice}}</b>
    </div>
    <div *ngIf="!isCredit">Recommendation (3 months):
      <b>{{report.Rating}}</b>
    </div>
    <div *ngIf="!isCredit">Recommendation (3 years):
      <b>{{report.RatingLongTerm}}</b>
    </div>
    <div>Created:
      <b>{{report.CreatedDate}}</b>
    </div>
    <div>Published:
      <b>{{report.PublishedDate}}</b>
    </div>
    <div>KeyRatioName:
      <b>{{report.RatioName}}</b>
    </div>
    <div *ngIf="!isCredit">DCF:
      <b>{{report.SharePriceCurrency}} {{report.DCF}}</b>
    </div>
    <div>ReportId:
      <b>{{report.RatioId}}</b>
    </div>
    <div>Reporting currency:
      <b>{{report.Currency}}</b>
    </div>
    <div *ngIf="isCredit">Report quartal exist:
        <b>{{report.ReportQuarterExist}}</b>
      </div>
  </div>
</div>
