import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";

import {
  CompanyRole,
  CompanyData,
  FileData,
  CompanySharePrice
} from "./web-api.models";
import { environment } from 'environments/environment';

@Injectable()
export class WebApiService {
  get baseUrlGrow() {
    return environment.baseUrlGrowApi;
  }

  get baseUrlReonApi() {
    return environment.baseUrlReonApi;
  }

  constructor(private http: HttpClient) { }

  getReports(companyId: number): Observable<App.WebApi.ReportDisplay[]> {
    return this.http
      .get<App.WebApi.Report[]>(this.baseUrlGrow + "v1/keyratiosworkflow?companyId=" + companyId, {
        withCredentials: true
      })
      .pipe(
        map(this.extractReports)
      );
  }

  private extractReports(data: App.WebApi.Report[]): App.WebApi.ReportDisplay[] {
    return data.map(r => {
      return {
        $id: r.$id,
        RatioId: r.KeyRatioId,
        RatioName: r.KeyRatioName,
        Currency: r.Currency,
        Rating: r.Rating,
        RatingLongTerm: r.RatingLongTerm,
        DCF: Math.round(r.DCF * 100) / 100, // Niwi: Is this a good place? Will it be used somewhere else?
        TargetPrice: r.TargetPrice,
        CreatedDate: new Date(r.CreatedDate).toSweString(),
        PublishedDate: new Date(r.PublishedDate).toSweString(),
        ReportStatus: r.ReportStatus,
        Analyst: r.LeadAnalyst,
        ReportPeriodYear: r.ReportPeriodYear,
        ReportPeriodQuartal: r.ReportPeriodQuartal,
        SharePriceCurrency: r.SharePriceCurrency,
        ReportAnalyst: r.ReportLeadAnalyst,
        isActive: false
      };
    });
  }
  getCreditReports(companyId: number): Observable<App.WebApi.ReportDisplay[]> {
    return this.http
      .get<App.WebApi.CreditReport[]>(
        this.baseUrlGrow + "v1/creditkeyratiosworkflow?companyId=" + companyId,
        { withCredentials: true }).pipe(
          map(this.extractCreditReports));
  }

  private extractCreditReports(data: App.WebApi.CreditReport[]): App.WebApi.ReportDisplay[] {
    return data.map(r => {
      return {
        $id: r.$id,
        RatioId: r.CreditRatioId,
        RatioName: r.CreditRatioName,
        Currency: r.Currency,
        CreatedDate: new Date(r.CreatedDate).toSweString(),
        PublishedDate: new Date(r.PublishedDate).toSweString(),
        ReportStatus: r.ReportStatus,
        Analyst: r.CreditAnalyst,
        ReportPeriodYear: r.ReportPeriodYear,
        ReportPeriodQuartal: r.ReportPeriodQuartal,
        ReportAnalyst: r.ReportCreditAnalyst,
        ReportQuarterExist: r.ReportQuarterExist,
        isActive: false
      };
    });
  }

  getSharePrices(companyId: Number): Observable<CompanySharePrice> {
    return this.http
      .get(
        this.baseUrlGrow +
        "v1/shareprices/getshareprices?companyid=" +
        companyId,
        { withCredentials: true }
      ).pipe(
        map(this.extractSharePrices));
  }

  private extractSharePrices(data: any): CompanySharePrice {

    var ret = new CompanySharePrice();

    ret.CompanyId = data.CompanyId;

    ret.OfficialClosePrice =
      data.OfficialClosePrice == null ? null : data.OfficialClosePrice;
    ret.OfficialCloseDate =
      data.OfficialClosePrice == null ? null : new Date(data.OfficialCloseDate);

    ret.LastPrice = data.LastPrice == null ? null : data.LastPrice;
    ret.LastDate = data.LastPrice == null ? null : new Date(data.LastDateTime);

    ret.TodayClosePrice =
      data.TodayClosePrice == null ? null : data.TodayClosePrice;
    ret.TodayCloseTime =
      data.TodayClosePrice == null ? null : new Date(data.TodayCloseTime);

    ret.Currency = data.Currency;
    ret.SourceType = data.SourceType;
    ret.ManualPrice = data.ManualPrice;

    return ret;
  }

  saveIntradayPrice(data: App.WebApi.SaveIntradayPrice): Observable<any> {
    return this.http
      .post(this.baseUrlGrow + "v1/shareprices/saveintradayprice", data, {
        withCredentials: true
      });
  }

  updateIntradayPriceFormulas(data: App.WebApi.SaveIntradayPrice): Observable<any> {
    return this.http
      .post(
        this.baseUrlGrow + "v1/shareprices/updateintradaypriceformulas",
        data,
        { withCredentials: true }
      );
  }

  private companiesForUserCache: CompanyRole[];
  getCompaniesForUser(): Observable<CompanyRole[]> {
    if (this.companiesForUserCache) {
      return of<CompanyRole[]>(this.companiesForUserCache);
    }

    return this.http
      .get<App.WebApi.Company[]>(this.baseUrlGrow + "v1/companyuser", { withCredentials: true })
      .pipe(
        map((data: App.WebApi.Company[]) => {
          //Spara bara "Lead Analyst" och "Co-Lead Analyst" och "Credit Analyst"
          let companies = data.filter(c => {
            return c.Role.find(r => {
              return r.CodId == 16 || r.CodId == 17 || r.CodId == 368;
            });
          });
          //Convert from App.WebApi.Company[] to CompanyRole[]
          this.companiesForUserCache = companies.map<CompanyRole>(c => {
            return {
              companyId: c.CompanyId,
              companyName: c.CompanyName,
              role:
                c.Role[0].CodId == 16 ? "I" : c.Role[0].CodId == 17 ? "II" : "C"
            };
          }); //.sort((a, b) => { return a.role.localeCompare(b.role);})
          return this.companiesForUserCache;
        }));
  }

  private growCompaniesCache: CompanyData[];
  getGrowCompanies(): Observable<CompanyData[]> {
    if (this.growCompaniesCache)
      return of<CompanyData[]>(this.growCompaniesCache);

    return this.http
      .get(this.baseUrlGrow + "v1/companiesworkflow", { withCredentials: true })
      .pipe(
        map((companies: any[]) => {
          //Convert to CompanyData[]
          this.growCompaniesCache = companies.map<CompanyData>(c => {
            return {
              id: c.Id,
              name: c.Name,
              lname: c.Name.toLowerCase(),
              price: c.Price,
              priceDate: c.PriceDate,
              isCovered: c.CStatus == "Normal",
              hasReports: c.HasReports,
              nameAlias: (c.CompanyNameAlias as string[]).map(x =>
                x.toLowerCase()
              ),
              currency: c.SharePriceCurrency,
              companyType: c.CompanyType,
              isEquity: c.IsEquity,
              isCredit: c.IsCredit
            };
          });
          return this.growCompaniesCache;
        }));
  }

  private startOptionsCache: App.WebApi.StartOptionsResponse;
  getStartOptions(): Observable<App.WebApi.StartOptionsResponse> {
    if (this.startOptionsCache)
      return of<App.WebApi.StartOptionsResponse>(this.startOptionsCache);
    return this.http
      .get<App.WebApi.StartOptionsResponse>(this.baseUrlReonApi + "internalWorkflow/GetStartOptions", {
        withCredentials: true
      })
      .pipe(
        map((data) => {
          this.startOptionsCache = data;
          this.startOptionsCache.FrontPageUrls[0].isSelected = true;
          return this.startOptionsCache;
        }));
  }

  private processFileResponse(res: HttpResponse<Blob>) {
    console.log("processFileResponse", res);
    const fileName = this.getFileName(res.headers.get("Content-Disposition"));
    console.debug("updateDocument filename", fileName);
    const fileData: FileData =
    {
      fileName: fileName,
      blob: res.body
    };
    return fileData;
  }

  createDocument(data: App.WebApi.CreateDocumentRequest): Observable<FileData> {
    return this.http
      .post(this.baseUrlReonApi + "internalWorkflow/CreateDocument", data, {
        responseType: 'blob' as 'json',
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((res: HttpResponse<Blob>) => this.processFileResponse(res)));
  }

  getDocument(model: App.WebApi.CreateDocumentRequest): Observable<FileData> {
    return this.http
      .get(this.baseUrlReonApi + "internalWorkflow/CreateDocument", {
        responseType: 'blob' as 'json',
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((res: HttpResponse<Blob>) => this.processFileResponse(res)));
  }

  private getFileName(contentDisposition: string): string {
    if (!contentDisposition) return null;
    //decodeURIComponent
    let start = contentDisposition.indexOf("filename*=utf-8''") + 17;
    let end = contentDisposition.indexOf(";", start);
    if (end <= 0) end = contentDisposition.length;
    let txt = contentDisposition.substring(start, end);
    txt = decodeURIComponent(txt);
    return txt.replace(/\"/g, "").replace(/ /g, "_");
  }

  public getDocumentInfoFromFile(
    file: File
  ): Observable<App.WebApi.CreateDocumentRequest> {
    let formData: FormData = new FormData();
    formData.append("uploadFile", file, file.name);
    return this.http
      .post<App.WebApi.CreateDocumentRequest>(
        this.baseUrlReonApi + "internalWorkflow/GetDocumentInfoFromFile",
        formData,
        { withCredentials: true }
      );
  }


  public updateDocument(file: File, data: App.WebApi.CreateDocumentRequest): Observable<FileData> {
    let formData: FormData = new FormData();
    formData.append("uploadFile", file, file.name);
    formData.append("data", JSON.stringify(data));
    return this.http
      .post(this.baseUrlReonApi + "internalWorkflow/UpdateDocument", formData, {
        responseType: 'blob' as 'json',
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((res: HttpResponse<Blob>) => this.processFileResponse(res)),
        catchError(response => {
          const responseBlob =
            response instanceof HttpResponse ? response.body :
              (response as any).error instanceof Blob ? (response as any).error : undefined;
          return this.blobToText(responseBlob).pipe(
            map((x: string) => {
              throw new Error(x)
            })
          )
        })
      );
  }

  private templateNameCache: App.WebApi.GetTemplatesResponse[];
  getTemplates(): Observable<App.WebApi.GetTemplatesResponse[]> {
    if (this.templateNameCache)
      return of<App.WebApi.GetTemplatesResponse[]>(this.templateNameCache);
    return this.http
      .get(this.baseUrlReonApi + "internalWorkflow/GetTemplates", {
        withCredentials: true
      }).pipe(
        map((data: any) => this.templateNameCache = data)
      );
  }

  public getPreviewData(
    data: App.WebApi.RenderHtmlTableRequest
  ): Observable<string> {
    return this.http
      .post(this.baseUrlReonApi + "renderHtmlTable/getTable", data, {
        withCredentials: true,
        responseType: 'text'
      });
  }

  createWrapDocument(
    data: App.WebApi.CreateWrapDocumentRequest
  ): Observable<FileData> {
    //Lägger till "Content-Type" i header för att slippa att Chrome skickar en "OPTIONS" före "POST"
    //let h = new Headers();
    //h.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post(this.baseUrlReonApi + "internalWorkflow/CreateWrapDocument", data, {
        responseType: 'blob' as 'json',
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((res: HttpResponse<Blob>) => this.processFileResponse(res)));
  }

  blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next("");
        observer.complete();
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          observer.next((event.target as any).result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }
}
