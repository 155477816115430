import { Injectable } from '@angular/core';

@Injectable()
export class GlobalDataService
{
	public selectedCompanyId: number;
	public oneliners: string[];

	constructor() 
	{ 
      this.oneliners = [
        "A diamond is a piece of coal that stuck with the job.",
        "Plan for many pleasures ahead.",
        "Don't forget, you are always on our minds.",
        "Don't ask, don't say. Everything lies in silence.",
        "80% of the results flow from 20% of the activities.",
        "You are the crunchy noodle in the vegetarian salad of life.",
        "Always forgive your enemies; nothing annoys them so much.",
        "Apologies, time has stopped.",
        "Biography lends to death a new terror.",
        "Change before you have to.",
        "Common sense isn't.",
        "Compliments cost nothing yet can give so much.",
        "Computers are useless. They can only give you answers.",
        "Consistency is the last refuge of the unimaginative.",
        "Cut the cake!",
        "Don't agonize. Organize!",
        "Everything bows to success, even grammar.",
        "Excellence is to do a common thing in an uncommon way.",
        "First thing's first.",
        "Forget yesterday. Tomorrow will be a golden day for you.",
        "Get out of the dogma house.",
        "Good. Fast. Cheap. (Pick two.)",
        "Happy 50th birthday! Now you don't have to make any sense.",
        "Happy New Year!",
        "If the idea you had three days ago still looks good, do it!",
        "If you think you understand people, you haven't been watching them closely enough.",
        "In all matters of opinion, our adversaries are insane.",
        "It is only the modern that ever becomes old-fashioned.",
        "Judgment comes from experience. Experience comes from bad judgment.",
        "Learn to laugh at yourself. You'll always be amused.",
        "Let us be thankful for the fools. Without them the rest of us couldn't succeed.",
        "Let your personality shine today.",
        "Let's keep our plans secret for now. ",
        "Love is like a pair of socks: you need two that match.",
        "Luck will visit you on the next full moon.",
        "Many admire your social and physical appearance.",
        "Many questions are unanswerable.",
        "Middle age is having a choice between two temptations and choosing the one that'll get you home earlier.",
        "Never reveal the bottom of your purse or the depth of your mind.",
        "Never take investment advice from someone who is working.",
        "No one's been hurt from laughing too much.",
        "Nothing is impossible for the man who doesn't have to do it himself.",
        "One is not old until regrets take place of dreams.",
        "One should always be a little improbable.",
        "Please tell me the opinion. I will carry you a hug onion.",
        "Some cause happiness wherever they go; others whenever they go.",
        "Supercalifragilisticexpialidocious!",
        "The night life is for you.",
        "The size of the pencil is not as important as the quality of the writing.",
        "There are no defences against stupidity.",
        "There will always be delightful mysteries in your life.",
        "Time you enjoyed wasting, is not wasted time.",
        "When in doubt, mumble.",
        "Wishing for the good old days only means you're old.",
        "Work will expand to fill the time allotted.",
        "Writing bores me so.",
        "You are a person of culture.",
        "You are a true romantic.",
        "You are talented in many ways.",
        "You can do anything except light a paper match on a marshmallow under water.",
        "You may go now. Your brain is full.",
        "Your future looks bright.",
        "Your future looks bright. But your past, what happened there?",
        "You're sitting on the right horse.",
        "You need to have fun!",
        "It's hard to predict which way the cabbage will roll.",
        "Fun fact nr 34: not all eggs are egg-shaped.",
        "Your submission may be edited for clarity.",
        "I have no idea where this will lead us, but I have a definite feeling it will be a place both wonderful and strange.",
        "New systems generate new problems.",
        "After all is said and done, a lot more is said than done.",
        "If you end up with the incorrect answer, try multiplying by the page number.",
        "Fill what's empty. Empty what's full. And scratch where it itches.",
        "A man with a watch knows what time it is. A man with two watches is never sure.",
        "Computers don't make errors… What they do, they do on purpose.",
        "Flowers: the genitals of plants.",
        "Don't be irreplaceable: if you can't be replaced, you can't be promoted.",
        "Eat one live insect each morning and your day will only get better.",
        "If you don't know what to do, walk fast and look concerned.",
        "No one gets sick on Wednesdays.",
        "What would Walker, Texas Ranger do?",
        "Excess of analysis causes paralysis.",
        "You can't misspell numbers when you write them as digits, but you can still miscount.",
        "Oh! To be young enough to know it all again.",
        "You may say that I'm a dreamer, but I'm not the only one.",
        "You're making a mistake with the reindeer.",
        "Greetings, my friend!",
        "We are all interested in the future.",
        "Forget it. It's too risky.",
        "My name is Richard. So what else do you need to know?",
        "What are you doing? We don't stop here!",
        "That was refreshing. I'm refreshed. I'm refreshing.",
        "I don't like sand. It's coarse and rough and irritating and it gets everywhere.",
        "Why so serious?",
        "I drink your milkshake!",
        "They call it a Royale with cheese.",
        "Roads? Where we're going we don't need roads.",
        "To infinity and beyond!",
        "Some people grow up and some people just grow older.",
        "Everybody dance! *happy dance*",
        "Try to look official.",
        "It is accomplished! It is accomplished!",
        "Leave the gun. Take the cannoli.",
        "There's always parking space when driving a 4X4.",
        "The happiest person in the company should not be trusted.",
        "A man who walks through the turnstiles backwards is going to Bangkok.",
        "All generalizations are false, including this one.",
        "You cannot stop the ongoing love affair between pasta sauce and a white dress shirt.",
        "Your parents' advice only makes sense 20 years after they gave it to you.",
        "The future is like now, only longer.",
        "Never mud-wrestle with a pig. You both get dirty, and the pig likes it.",
        "No, Mr. Bond. I expect you to die!",
        "Live fast and die young! ...too late for that.",
        "The sky is not the limit. Don't forget about space!",
        "Will we get in trouble for this?",
        "And I'm eating this banana. Lunchtime be damned!",
        "Everyone's going to talk about this!",
        "From outer space - a warning.",
        "Not that it matters, but most of this is true.",
        "Some days the wind doesn't blow: it sucks.",
        "Nobody grows up quite like they imagined.",
        "There are three parts to every great trilogy.",
        "When folks accuse me of not having a sense of humor, I lose my sense of humor.",
        "Not sure if you're hungry? Play it safe, have a meal.",
        "...those times when you think you're an idiot and no one tries to contradict you.",
        "The lack of a singing voice is sometimes compensated by a great desire to express oneself through song.",
        "If you don't do the dishes, your next meal will be more nutritious.",
        "When has a moldy cheese gone bad?",
        "To get a good night's sleep, it is important to go to bed the day before you wish to get up.",
        "You're still young until your child has donated their used cell phone to you.",
        "You're probably doing this wrong. Contact an editor."
      ];
	}
	getOneliner(): string
	{
		let rand = Math.floor(Math.random() * this.oneliners.length);
		return this.oneliners[rand];
	}


    //Kanske ska flyttas till en helperklass

	saveData = (function ()
	{
		if (window.navigator && window.navigator.msSaveOrOpenBlob)
		{
			return function (blob, fileName) { window.navigator.msSaveOrOpenBlob(blob, fileName); };
		}
		else
		{
			var a = document.createElement("a");
			document.body.appendChild(a);
			a.style.display = "none";
			return function (blob, fileName)
			{
				var url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(url);
			};
		}
	}());

}
