import { Component, OnInit } from "@angular/core";

import { GlobalDataService, SharePriceService, WebApiService } from "../../services";
import {
  CompanyRole,
  CompanyData,
} from "../../services/web-api.models";

import "rxjs/Rx";
import { environment } from 'environments/environment';

@Component({
  selector: "app-generate-document",
  templateUrl: "./generate-document.component.html",
  styleUrls: ["./generate-document.component.css"]
})
export class GenerateDocumentComponent implements OnInit {
  errorMessage: string;
  sharePriceErrorMessage: string;
  cType: string = "Equity";
  reports: App.WebApi.ReportDisplay[];
  companies: CompanyRole[];
  startOptions: App.WebApi.StartOptionsResponse;
  allCompanies: CompanyData[];
  searchString: string;
  filteredReportReasons: App.WebApi.ValueName[];

  selectedCompany: CompanyData;
  selectedCompanyId: number = 0;
  selectedReport1: App.WebApi.ReportDisplay;
  selectedReport2: App.WebApi.ReportDisplay;
  selectedTemplateEngine: App.WebApi.ValueName = { Value: 0, Name: null };

  isCredit: boolean = false;

  selectedReportType: App.WebApi.ReportTypeValueName;
  selectedReportReason: App.WebApi.ValueName;
  sentToCompany: boolean;
  mandateToIssueBonds: boolean;
  adHocReport: boolean;
  isCreatingReport: boolean = false;
  oneliner: string;

  noReportReasonSelected: boolean;
  noReportTypeSelected: boolean;
  noCompanySelected: boolean;
  noSentToCompanySet: boolean;
  noMandateToIssueBondsSet: boolean;
  noAdHocReportSet: boolean;

  constructor(
    public webApi: WebApiService,
    public globalData: GlobalDataService,
    public sharePrice: SharePriceService
  ) {
    this.errorMessage = null;
  }

  ngOnInit() {
    this.getCompaniesForUser();
    this.getStartOptions();
    this.searchCompany(); //gets all companies

    if (this.globalData.selectedCompanyId)
      this.selectCompany({
        companyId: this.globalData.selectedCompanyId,
        role: null,
        companyName: null
      });

    this.sharePrice.errorMessage.subscribe(
      msg => (this.sharePriceErrorMessage = msg)
    );

    this.sharePrice.getSharePrice(this.selectedCompanyId);
  }

  cTypeChanged(e: Event) {
    this.isCredit = this.cType == "Credit";
    if (this.selectedCompany) {
      this.getReports(this.selectedCompany.id);
      this.selectedReportType = null;
      this.selectedReportTypeChanged(e);
    }

    this.noCompanySelected = false;
    this.noReportReasonSelected = false;
    this.noReportTypeSelected = false;
    this.noSentToCompanySet = false;
    this.noMandateToIssueBondsSet = false;
    this.noAdHocReportSet = false;
    this.sharePrice.setDefault();

    if (!this.isCredit) {
      this.selectedReportType = null;
    } else {
      this.selectedReportType = this.startOptions.ReportTypes[6];
    }
  }

  selectedReportTypeChanged(e: Event) {
    //Uppdatera den filtrerade listan av ReportReasons om vald ReportType ändrats
    if (!this.selectedReportType) {
      this.filteredReportReasons = [];
      this.selectedReportReason = null;
    } else {
      this.filteredReportReasons = this.startOptions.ReportReasons.filter(x =>
        this.selectedReportType.ValidReportReasons.find(vrr => vrr == x.Value)
      );
      if (this.selectedReportReason == null) {
        if (this.selectedReportType.DefaultReportReason > 0) {
          let newReason = this.filteredReportReasons.find(
            x => x.Value == this.selectedReportType.DefaultReportReason
          );
          this.selectedReportReason = newReason == undefined ? null : newReason;
        }
      } else if (
        !this.filteredReportReasons.find(x => x == this.selectedReportReason)
      )
        this.selectedReportReason = null;
    }

    this.noReportTypeSelected = !this.selectedReportType;
  }

  selectedReasonChanged() {
    this.noReportReasonSelected = !this.selectedReportReason;
  }

  selectCompany(c: CompanyRole) {
    let comp = this.allCompanies.find(x => {
      return x.id == c.companyId;
    });

    this.selectSearchCompany(comp);
  }

  selectSearchCompany(c: CompanyData) {
    this.selectedCompany = c;
    if (c == null) {
      this.searchString = null;
      this.selectedCompanyId = 0;
      this.getReports(0);
      this.globalData.selectedCompanyId = 0;
      this.sharePrice.getSharePrice(0);
    } else {
      this.searchString = c.name;
      this.selectedCompanyId = c.id;
      this.getReports(c.id);
      this.globalData.selectedCompanyId = c.id;
      this.sharePrice.getSharePrice(c.id);
    }

    // Reset UI settings
    this.isCredit = this.cType == "Credit";
    this.noCompanySelected = this.selectedCompanyId <= 0;
    this.selectedReportReason = null;
    this.sentToCompany = false;
    this.mandateToIssueBonds = false;
    this.adHocReport = false;
  }

  getReports(companyId: number) {
    this.reports = null;
    this.selectedReport1 = null;
    this.selectedReport2 = null;
    if (companyId == 0) return;

    if (this.cType == "Credit") {
      this.webApi.getCreditReports(companyId).subscribe(
        reports => {
          this.reports = reports;
          if (reports && reports.length > 0) {
            this.selectedReport1 = reports[0];
            this.selectedReport1.isActive = true;
            if (reports[0].ReportStatus == "Under Work" && reports.length > 1) {
              this.selectedReport2 = reports[1];
              this.selectedReport2.isActive = true;
            }
          }
        },
        error => (this.errorMessage = error)
      );
    } //Equity
    else {
      this.webApi.getReports(companyId).subscribe(
        reports => {
          this.reports = reports;
          if (reports && reports.length > 0) {
            this.selectedReport1 = reports[0];
            this.selectedReport1.isActive = true;
            if (reports[0].ReportStatus == "Under Work" && reports.length > 1) {
              this.selectedReport2 = reports[1];
              this.selectedReport2.isActive = true;
            }
          }
        },
        error => (this.errorMessage = <any>error)
      );
    }
  }

  getCompaniesForUser() {
    this.webApi
      .getCompaniesForUser()
      .subscribe(
        companies => (this.companies = companies),
        error => (this.errorMessage = <any>error)
      );
  }

  getStartOptions() {
    this.webApi.getStartOptions().subscribe(
      startOptions => {
        this.startOptions = startOptions;
      },
      error => (this.errorMessage = <any>error)
    );
  }

  searchCompany(): void {
    if (!this.allCompanies) {
      this.allCompanies = [];
      this.webApi
        .getGrowCompanies()
        .subscribe(
          companies => (this.allCompanies = companies),
          error => (this.errorMessage = <any>error)
        );
    }
  }

  get filteredCompanies(): CompanyData[] {
    if (!this.allCompanies || !this.searchString) return [];
    let low = this.searchString.toLowerCase();
    return this.allCompanies.filter(c => {
      return c.lname.startsWith(low) || c.nameAlias.includes(low);
    });
  }

  get filteredReportTypes(): App.WebApi.ReportTypeValueName[] {
    //Visa bara rapporttyper som är giltiga för detta företag
    return this.startOptions.ReportTypes.filter(
      x => ((x.Value == 7 || x.Value==13) && this.isCredit)  || (x.Value != 7 &&  x.Value!=13 && !this.isCredit)
    );
  }

  get reonApiBaseUrl(): string {
    return environment.baseUrlReonApi;
  }

  get showSelectFrontPage(): boolean {
    return this.selectedReportType && this.selectedReportType.SelectFrontPage;
  }

  selectedFrontPage(page: App.WebApi.ValueName) {
    if (this.startOptions) {
      this.startOptions.FrontPageUrls.forEach(x => (x.isSelected = false));
      page.isSelected = true;
    }
  }

  getOneliner(): string {
    return this.globalData.getOneliner();
  }

  sentToCompanyChanged(){
    this.noSentToCompanySet = !this.sentToCompany;
  }

  mandateToIssueBondsChanged(){
    this.noMandateToIssueBondsSet = this.isCredit && !this.mandateToIssueBonds;
  }

  adHocReportChanged(){
    this.noAdHocReportSet = this.isCredit && !this.adHocReport;
  }

  sharePriceSelectChange() {
    this.sharePrice.hasSelectedPrice(this.isCredit);
  }

  createReport() {
    this.noCompanySelected = this.selectedCompanyId <= 0;
    this.noReportReasonSelected = !this.selectedReportReason && !this.isCredit;
    this.noReportTypeSelected = !this.selectedReportType;
    this.noSentToCompanySet = !this.sentToCompany;
    this.noMandateToIssueBondsSet = this.isCredit && !this.mandateToIssueBonds;
    this.noAdHocReportSet = this.isCredit && !this.adHocReport;
    this.sharePrice.hasSelectedPrice(this.isCredit);
    if (
      this.noCompanySelected ||
      this.noReportReasonSelected ||
      this.noReportTypeSelected ||
      this.reports == null ||
      this.noSentToCompanySet ||
      this.noMandateToIssueBondsSet ||
      this.noAdHocReportSet ||
      this.sharePrice.noSharePriceSelected ||
      this.sharePrice.invalidNumber
    )
      return;

    if (!this.isCredit && this.sharePrice.isManualPrice()) {
      this.errorMessage = null;
      this.sharePrice.saveIntradayPrice(this.selectedCompanyId).subscribe(
        data => {},
        error => {
          console.log("Error saving the price.");
          this.errorMessage = "Couldn’t save the price, please try again";
        },
        () => {
          console.log("Completed saving the price.");
          this.generatefile();
        }
      );
    } else if (
      !this.isCredit && (
            this.sharePrice.isLastPrice() ||
            this.sharePrice.isPreliminaryPrice())
    ) {
      this.errorMessage = null;
      this.sharePrice
        .updateIntradayPriceFormulas(this.selectedCompanyId)
        .subscribe(
          data => {},
          error => {
            console.log("Error updating price formulas.");
            this.errorMessage =
              "Couldn’t update price formulas, please try again";
          },
          () => {
            console.log("Completed updating price formulas.");
            this.generatefile();
          }
        );
    } else {
      console.log("Going straight for generation");
      this.generatefile();
    }
  }

  generatefile() {
    this.oneliner = this.getOneliner();

    let model: App.WebApi.CreateDocumentRequest = {
      CompanyId: this.selectedCompanyId,
      ReportReason: this.isCredit ? 19 : this.selectedReportReason.Value, // Value "none" if credit report since we hide the reasons select
      ReportType: this.selectedReportType.Value,
      SentToCompany: this.sentToCompany,
      MandateToIssueBonds: this.mandateToIssueBonds,
      AdHocReport: this.adHocReport,
      FrontPageId: this.selectedReportType.SelectFrontPage
        ? this.startOptions.FrontPageUrls.find(x => x.isSelected).Value
        : 0,
      SharePriceStatus: this.isCredit
        ? 0
        : this.sharePrice.selectedSharePrice.type,
      SharePriceDate: this.isCredit
        ? new Date().toString()
        : new Date(this.sharePrice.selectedSharePrice.date.getUTCFullYear(),this.sharePrice.selectedSharePrice.date.getUTCMonth(),this.sharePrice.selectedSharePrice.date.getUTCDate(),this.sharePrice.selectedSharePrice.date.getUTCHours(),
        this.sharePrice.selectedSharePrice.date.getUTCMinutes(),this.sharePrice.selectedSharePrice.date.getUTCSeconds()).toSweString(),
      TemplateCreationEngine: this.selectedTemplateEngine
        ? this.selectedTemplateEngine.Value
        : 0
    };
    if (this.selectedReport1 != null) {
      model.ReportId1 = this.selectedReport1.RatioId;
      model.ReportPeriodYear = this.selectedReport1.ReportPeriodYear;
      model.ReportPeriodQuartal = this.selectedReport1.ReportPeriodQuartal;
      model.ReportQuarterExist = this.selectedReport1.ReportQuarterExist;
      if (this.selectedReport2 != null)
        model.ReportId2 = this.selectedReport2.RatioId;
    }

    this.downloadfile(model);
  }

  downloadfile(data: App.WebApi.CreateDocumentRequest) {
    this.isCreatingReport = true;
    this.errorMessage = null;
    this.webApi.createDocument(data).subscribe(
      data => {
        this.globalData.saveData(data.blob, data.fileName);
      }, //console.log(data),
      error => {
        console.log("Error downloading the file.");
        this.isCreatingReport = false;
        this.errorMessage = error;
      },
      () => {
        console.log("Completed file download.");
        this.isCreatingReport = false;
      }
    );
  }
}
