import { Component, OnInit } from "@angular/core";
import { GenerateDocumentComponent } from "../generate-document/generate-document.component";
import { GlobalDataService, SharePriceService, WebApiService } from "../../services";

@Component({
  selector: "data-preview",
  templateUrl: "./data-preview.component.html",
  styleUrls: [
    "../generate-document/generate-document.component.css",
    "./data-preview.component.css"
  ]
})
export class DataPreviewComponent extends GenerateDocumentComponent {
  templates: App.WebApi.GetTemplatesResponse[];
  selectedTemplates: App.WebApi.GetTemplatesResponse = null;
  selectedTemplate: App.WebApi.TemplateName;
  htmlTable: string = "";

  constructor(
    webApi: WebApiService,
    globalData: GlobalDataService,
    sharePrice: SharePriceService
  ) {
    super(webApi, globalData, sharePrice);
  }

  ngOnInit() {
    this.getCompaniesForUser();
    this.searchCompany(); //gets all companies
    this.getTemplates();

    this.sharePrice.errorMessage.subscribe(
      msg => (this.sharePriceErrorMessage = msg)
    );

    if (this.globalData.selectedCompanyId)
      this.selectCompany({
        companyId: this.globalData.selectedCompanyId,
        role: null,
        companyName: null
      });
    else this.sharePrice.getSharePrice(0);
  }

  getTemplates() {
    this.webApi
      .getTemplates()
      .subscribe(
        templates => (this.templates = templates),
        error => (this.errorMessage = <any>error)
      );
  }

  loadPreviewData() {
    this.sharePrice.hasSelectedPrice(this.isCredit);
    if (this.sharePrice.noSharePriceSelected || this.sharePrice.invalidNumber)
      return;

    this.noCompanySelected = this.selectedCompanyId <= 0;
    if (this.noCompanySelected) return;

    if (this.sharePrice.isManualPrice()) {
      this.errorMessage = null;
      this.sharePrice.saveIntradayPrice(this.selectedCompanyId).subscribe(
        data => {},
        error => {
          console.log("Error saving the price.");
          this.errorMessage = "Couldn’t save the price, please try again";
        },
        () => {
          console.log("Completed saving the price.");
          this.renderTable();
        }
      );
    } else if (
      this.sharePrice.isLastPrice() ||
      this.sharePrice.isPreliminaryPrice()
    ) {
      this.errorMessage = null;
      this.sharePrice
        .updateIntradayPriceFormulas(this.selectedCompanyId)
        .subscribe(
          data => {},
          error => {
            console.log("Error updating price formulas.");
            this.errorMessage =
              "Couldn’t update price formulas, please try again";
          },
          () => {
            console.log("Completed updating price formulas.");
            this.renderTable();
          }
        );
    } else {
      this.renderTable();
    }
  }
  renderTable() {
    this.isCreatingReport = true;
    this.errorMessage = null;
    this.htmlTable = "";

    let model: App.WebApi.RenderHtmlTableRequest = {
      Id: this.selectedCompanyId,
      TId: this.selectedTemplate.Name,
      SharePriceStatus: this.sharePrice.selectedSharePrice.type,
      SharePriceDate: new Date(this.sharePrice.selectedSharePrice.date.getUTCFullYear(),this.sharePrice.selectedSharePrice.date.getUTCMonth(),this.sharePrice.selectedSharePrice.date.getUTCDate(),this.sharePrice.selectedSharePrice.date.getUTCHours(),
      this.sharePrice.selectedSharePrice.date.getUTCMinutes(),this.sharePrice.selectedSharePrice.date.getUTCSeconds()).toSweString(),
      AutoWidth: true
    };

    if (this.selectedReport1 != null) {
      model.ReportIds = "(" + this.selectedReport1.RatioId;
      if (this.selectedReport2 != null)
        model.ReportIds += "," + this.selectedReport2.RatioId;
      model.ReportIds += ")";
    }
    this.webApi.getPreviewData(model).subscribe(
      html => (this.htmlTable = html),
      error => {
        this.errorMessage = error;
        this.isCreatingReport = false;
      },
      () => {
        this.isCreatingReport = false;
      }
    );
  }

  visibleTemplates(): App.WebApi.TemplateName[] {
    return this.selectedTemplates.Templates.filter(x => this.showTemplate(x));
  }
  showTemplate(t: App.WebApi.TemplateName): boolean {
    if (!this.selectedCompany || !t.HideForComapnyType) return true;
    let ct = this.selectedCompany.companyType;
    if (ct && ct != "Bank" && ct != "Insurance") ct = "Normal";
    return t.HideForComapnyType.indexOf(ct) == -1;
  }
}
