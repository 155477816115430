import { Router } from '@angular/router';
import { AuthorizeService } from './authorize.service';
import { tap } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';
import * as i0 from "@angular/core";
import * as i1 from "./authorize.service";
import * as i2 from "@angular/router";
export class AuthorizeGuard {
    constructor(authorize, router) {
        this.authorize = authorize;
        this.router = router;
    }
    canActivate(_next, state) {
        return this.authorize.isAuthenticated()
            .pipe(tap(isAuthenticated => this.handleAuthorization(isAuthenticated, state)));
    }
    handleAuthorization(isAuthenticated, state) {
        if (!isAuthenticated) {
            this.router.navigate(ApplicationPaths.LoginPathComponents, {
                queryParams: {
                    [QueryParameterNames.ReturnUrl]: state.url
                }
            });
        }
    }
}
AuthorizeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizeGuard_Factory() { return new AuthorizeGuard(i0.ɵɵinject(i1.AuthorizeService), i0.ɵɵinject(i2.Router)); }, token: AuthorizeGuard, providedIn: "root" });
