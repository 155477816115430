<div class="container">
  <div class="row" style="padding-top: 12px; margin-bottom: 60px;">
    <div class="col-md-8">
      <h3>1. Select document to update</h3>
      <div class="section">
        <input
          type="file"
          (change)="fileChange($event)"
          placeholder="Upload file"
          accept=".docx"
        />
      </div>

      <h3 style="padding-top: 16px;">2. Selected company</h3>
      <div class="section">
        <div class="input-group">
          <fa-icon [icon]="['fas','search']" [classes]="['input-group-addon']"></fa-icon>
          <input
            type="text"
            class="form-control"
            [ngModel]="selectedCompany?.name"
            disabled="disabled"
          />
        </div>
      </div>

      <h3 style="padding-top: 16px;">
        3. Select GROW report(s)
        <span
          *ngIf="selectedCompany"
          style="font-size:1rem; font-weight:normal;color:black; padding-left:12px;"
          ><label *ngIf="!isCredit">Equity</label
          ><label *ngIf="isCredit">Credit</label></span
        >
      </h3>
      <report-selector
        [reports]="reports"
        [(selectedReport1)]="selectedReport1"
        [(selectedReport2)]="selectedReport2"
        [isCredit]="isCredit"
      ></report-selector>

      <h3 style="padding-top: 16px;">4. Select publication type</h3>
      <div class="row section">
        <div class="col-md-6">
          <h5>Publication type</h5>
          <select
            class="form-control"
            [(ngModel)]="selectedReportType"
            *ngIf="startOptions"
          >
            <option [ngValue]="selectedReportType">{{
              selectedReportType.Name
            }}</option>
          </select>
        </div>
        <div class="col-md-6">
          <h5>Reason for writing</h5>
          <select
            class="form-control"
            [(ngModel)]="selectedReportReason"
            *ngIf="startOptions"
          >
            <option *ngFor="let obj of filteredReportReasons" [ngValue]="obj">{{
              obj.Name
            }}</option>
          </select>
        </div>
        <div class="col-md-12">
          <table style="line-height: 1em; margin-top: 8px;">
            <tr>
              <td style="vertical-align: top;">
                This report has been/will be sent to the Company prior to
                publication to check the accuracy of facts?
              </td>
              <td style="width: 80px;">
                <div>
                  <label
                    ><input
                      type="radio"
                      [(ngModel)]="sentToCompany"
                      name="sentToCompany"
                      value="true"
                    />
                    Yes</label
                  >
                </div>
                <div>
                  <label
                    ><input
                      type="radio"
                      [(ngModel)]="sentToCompany"
                      name="sentToCompany"
                      value="false"
                    />
                    No</label
                  >
                </div>
              </td>
            </tr>
            <tr
              *ngIf="isCredit"
              style="border-bottom:solid 1px gray; border-top:solid 1px gray; padding-top: 100px;"
            >
              <td style="vertical-align: top;">
                The preparation and and publishing of this report are related to
                Handelsbanken Capital Markets having a mandate to issue bonds
                for this company?
              </td>
              <td style="width: 80px;">
                <div>
                  <label
                    ><input
                      type="radio"
                      [(ngModel)]="mandateToIssueBonds"
                      name="mandateToIssueBonds"
                      value="true"
                    />
                    Yes</label
                  >
                </div>
                <div>
                  <label
                    ><input
                      type="radio"
                      [(ngModel)]="mandateToIssueBonds"
                      name="mandateToIssueBonds"
                      value="false"
                    />
                    No</label
                  >
                </div>
              </td>
            </tr>            
            <tr *ngIf="isCredit" style="border-top:solid 1px gray;">
              <td style="vertical-align: top;">
                This report has been prepared on an ad-hoc basis, which means
                that Handelsbanken Capital Markets is not taking up full
                coverage of the subject company as a creditor?
              </td>
              <td style="width: 80px;">
                <div>
                  <label
                    ><input
                      type="radio"
                      [(ngModel)]="adHocReport"
                      name="adHocReport"
                      value="true"
                    />
                    Yes</label
                  >
                </div>
                <div>
                  <label
                    ><input
                      type="radio"
                      [(ngModel)]="adHocReport"
                      name="adHocReport"
                      value="false"
                    />
                    No</label
                  >
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div style="display:none;">
        <h3 style="padding-top: 16px;">
          4.5. Select Template Engine (optional)
        </h3>
        <div class="row section">
          <div class="col-md-6">
            <h5>Template Engine</h5>
            <select
              class="form-control"
              [(ngModel)]="selectedTemplateEngine"
              *ngIf="startOptions"
            >
              <option
                *ngFor="let obj of startOptions.TemplateCreationEngines"
                [ngValue]="obj"
                >{{ obj.Name }}</option
              >
            </select>
          </div>
        </div>
      </div>

      <h3 style="padding-top: 16px;" *ngIf="!isCredit">
        5. Select share price
      </h3>
      <div class="row section" *ngIf="!isCredit">
        <div class="col-md-7">
          <h5>Share price</h5>
          <select
            class="form-control"
            [(ngModel)]="sharePrice.selectedSharePrice"
            (change)="sharePriceSelectChange()"
          >
            <option
              *ngFor="let obj of sharePrice.filteredSharePrice"
              [ngValue]="obj"
              [disabled]="!obj.active"
            >
              <div *ngIf="obj.active">
                {{ obj.description }}: {{ obj.price.toFixed(2) }}
                {{ obj.currenct }} ({{
                  obj.date.toSharePriceDateString(obj.showTime)
                }})
              </div>
              <div *ngIf="!obj.active">{{ obj.description }}</div>
            </option>
          </select>
        </div>
        <div class="col-md-3" *ngIf="sharePrice.allowManualPrice()">
          <h5>Manual price</h5>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="sharePrice.freeTextSharePrice"
            placeholder="Shareprice"
            (ngModelChange)="sharePrice.sharePriceFreeTextChange()"
          />
        </div>
      </div>

      <h3 style="padding-top: 16px;" *ngIf="!isCredit">6. Update document</h3>
      <h3 style="padding-top: 16px;" *ngIf="isCredit">5. Update document</h3>
      <div class="section">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="noFileSelected"
        >
            <strong>No document selected</strong> You must select a
            document to update.
        </div>
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="!noFileSelected && (sharePrice.noSharePriceSelected || sharePrice.invalidNumber)"
        >
          <div *ngIf="sharePrice.noSharePriceSelected">
            <strong>No shareprice selected!</strong> You must select a
            shareprice before creating a document.
          </div>
          <div *ngIf="sharePrice.invalidNumber">
            <strong>Not a valid number format!</strong>
          </div>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="sharePriceErrorMessage"
        >
          {{ sharePriceErrorMessage }}
        </div>
        <div style="margin: 8px 0;">
          <button
            class="btn btn-primary"
            (click)="updateReport()"
            [disabled]="isCreatingReport"
          >
            Update document
          </button>
          <span *ngIf="isCreatingReport" style="font-style:italic;">
            <fa-icon [icon]="['fas','cog']" [styles]="{'vertical-align': 'middle', 'margin-left': '10px' }" size="2x" spin="true"></fa-icon>
            {{ oneliner }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-4" style="background-color: #f0f0f0;">
      <h3>Report summary</h3>
      <div *ngIf="!selectedCompany" style="font-style: italic;">
        (No company selected)
      </div>
      <div *ngIf="selectedCompany">
        <div>
          Company: <b style="font-size: 22px;">{{ selectedCompany.name }}</b>
        </div>
        <div>
          Share price:
          <b *ngIf="sharePrice.selectedSharePrice != null"
            >{{
              sharePrice.selectedSharePrice.date.toSharePriceDateString(
                sharePrice.selectedSharePrice.showTime
              )
            }}: {{ sharePrice.selectedSharePrice.currency }}
            {{ sharePrice.selectedSharePrice.price?.toFixed(2) }}</b
          >
        </div>
        <div *ngIf="sharePrice.selectedSharePrice != null">
          Share price type:
          <b>{{ sharePrice.selectedSharePrice.description }}</b>
        </div>
        <div>
          Company type: <b>{{ selectedCompany.companyType }}</b>
        </div>
        <report-information
          [report]="selectedReport1"
          [reportNumber]="1"
          [hasChanged]="hasReport1Changed"
          [isCredit]="isCredit"
        ></report-information>
        <report-information
          [report]="selectedReport2"
          [reportNumber]="2"
          [hasChanged]="hasReport2Changed"
          [isCredit]="isCredit"
        ></report-information>
        <div style="margin-top: 8px;">
          Report Type:
          <b>{{
            selectedReportType ? selectedReportType.Name : "Not selected"
          }}</b>
        </div>
        <div style="margin-top: 8px; margin-bottom: 8px;">
          Report Reason:
          <b>{{
            selectedReportReason ? selectedReportReason.Name : "Not selected"
          }}</b>
          <fa-icon [icon]="['fas','edit']" [classes]="['activeColor']" 
            *ngIf="hasReasonChanged" title="{{ hasReasonChanged }}"></fa-icon>
        </div>
        <div *ngIf="isCredit">
          Mandate to issue bonds:
          <b>{{
            mandateToIssueBonds == null
              ? "Not selected"
              : mandateToIssueBonds == "true"
              ? "Yes"
              : "No"
          }}</b>
          <fa-icon [icon]="['fas','edit']" [classes]="['activeColor']" 
            *ngIf="hasMandateToIssueBondsChanged" title="{{ hasMandateToIssueBondsChanged }}"></fa-icon>
        </div>
        <div>
          Fact-checked by company:
          <b>{{
            sentToCompany == null
              ? "Not selected"
              : sentToCompany == "true"
              ? "Yes"
              : "No"
          }}</b>
          <fa-icon [icon]="['fas','edit']" [classes]="['activeColor']" 
            *ngIf="hasSentToCompanyChanged" title="{{ hasSentToCompanyChanged }}"></fa-icon>
        </div>
        <div *ngIf="isCredit">
          Ad-hoc report:
          <b>{{
            adHocReport == null
              ? "Not selected"
              : adHocReport == "true"
              ? "Yes"
              : "No"
          }}</b>
          <fa-icon [icon]="['fas','edit']" [classes]="['activeColor']" 
            *ngIf="hasAdHocReportChanged" title="{{ hasAdHocReportChanged }}"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
