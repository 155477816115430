import { Component, OnInit } from "@angular/core";

import { GlobalDataService, SharePriceService,  WebApiService } from "../../services";
import { CompanyRole, CompanyData } from "../../services/web-api.models";
import {  } from "../../services/share-price.service";

import "rxjs/Rx";

@Component({
  selector: "app-update",
  templateUrl: "./update-document.component.html",
  styleUrls: ["./update-document.component.css"]
})
export class UpdateDocumentComponent implements OnInit {
  errorMessage: string;
  sharePriceErrorMessage: string;
  reports: App.WebApi.ReportDisplay[];
  startOptions: App.WebApi.StartOptionsResponse;
  allCompanies: CompanyData[];
  filteredReportReasons: App.WebApi.ValueName[];

  selectedFile: File;
  noFileSelected: boolean = false;

  originalSettings: App.WebApi.CreateDocumentRequest;
  selectedCompany: CompanyData;
  selectedReport1: App.WebApi.ReportDisplay;
  selectedReport2: App.WebApi.ReportDisplay;

  selectedReportReason: App.WebApi.ValueName = { Value: null, Name: null };
  selectedReportType: App.WebApi.ReportTypeValueName = {
    Value: null,
    Name: null
  };
  selectedTemplateEngine: App.WebApi.ValueName = { Value: 0, Name: null };

  sentToCompany: string;
  mandateToIssueBonds: string;
  adHocReport: string;
  isCredit: boolean;
  isCreatingReport: boolean = false;
  oneliner: string;

  constructor(
    private webApi: WebApiService,
    private globalData: GlobalDataService,
    public sharePrice: SharePriceService
  ) {
    this.errorMessage = null;
  }

  ngOnInit() {
    this.getStartOptions();

    this.allCompanies = [];
    this.webApi
      .getGrowCompanies()
      .subscribe(
        companies => (this.allCompanies = companies),
        error => (this.errorMessage = error)
      );

    this.sharePrice.errorMessage.subscribe(
      msg => (this.sharePriceErrorMessage = msg)
    );
    this.sharePrice.getSharePrice(0);
  }

  getStartOptions() {
    this.webApi
      .getStartOptions()
      .subscribe(
        startOptions => (this.startOptions = startOptions),
        error => (this.errorMessage = error)
      );
  }

  get hasReasonChanged(): string {
    if (
      this.selectedReportReason == null ||
      this.selectedReportReason.Value != this.originalSettings.ReportReason
    )
      return (
        "Previous: " +
        this.startOptions.ReportReasons.find(
          x => x.Value == this.originalSettings.ReportReason
        ).Name
      );
    return null;
  }
  get hasReport1Changed(): string {
    let prev = this.originalSettings && this.originalSettings.ReportId1;
    let cur = this.selectedReport1 && this.selectedReport1.RatioId;
    if (prev != cur) return "Previous: " + (prev ? prev.toString() : "not set");
    return null;
  }
  get hasReport2Changed(): string {
    let prev = this.originalSettings && this.originalSettings.ReportId2;
    let cur = this.selectedReport2 && this.selectedReport2.RatioId;
    if (prev != cur) return "Previous: " + (prev ? prev.toString() : "not set");
    return null;
  }
  get hasSentToCompanyChanged(): string {
    if (
      this.sentToCompany != (!!this.originalSettings.SentToCompany).toString()
    )
      return (
        "Previous: " + (this.originalSettings.SentToCompany ? "Yes" : "No")
      );
    return null;
  }
  get hasMandateToIssueBondsChanged(): string {
    if (
      this.mandateToIssueBonds !=
      (!!this.originalSettings.MandateToIssueBonds).toString()
    )
      return (
        "Previous: " +
        (this.originalSettings.MandateToIssueBonds ? "Yes" : "No")
      );
    return null;
  }
  get hasAdHocReportChanged(): string {
    if (this.adHocReport != (!!this.originalSettings.AdHocReport).toString())
      return "Previous: " + (this.originalSettings.AdHocReport ? "Yes" : "No");
    return null;
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.errorMessage = null;

      this.selectedFile = fileList[0];
      this.webApi.getDocumentInfoFromFile(this.selectedFile).subscribe(
        settings => {
          this.originalSettings = settings;

          let comp = this.allCompanies.find(x => {
            return x.id == settings.CompanyId;
          });
          this.selectedCompany = comp;
          this.globalData.selectedCompanyId = settings.CompanyId;
          this.selectedReportType = this.startOptions.ReportTypes.find(
            x => x.Value == settings.ReportType
          );
          this.isCredit = this.selectedReportType.Value == 7; //7 => Credit Comment
          this.getReports(
            settings.CompanyId,
            settings.ReportId1,
            settings.ReportId2
          );
          this.filteredReportReasons = this.startOptions.ReportReasons.filter(
            x =>
              this.selectedReportType.ValidReportReasons.find(
                vrr => vrr == x.Value
              )
          );
          this.selectedReportReason = this.startOptions.ReportReasons.find(
            x => x.Value == settings.ReportReason
          );
          this.sentToCompany = (!!settings.SentToCompany).toString();
          this.mandateToIssueBonds = (!!settings.MandateToIssueBonds).toString();
          this.adHocReport = (!!settings.AdHocReport).toString();

          this.sharePrice.getSharePrice(this.selectedCompany.id);
        },
        error => (this.errorMessage = error)
      );
      this.sharePrice.hasSelectedPrice(this.isCredit);
      this.noFileSelected = false;
    }
  }

  getReports(companyId: number, selectReport1: number, selectReport2: number) {
    this.reports = null;
    this.selectedReport1 = null;
    this.selectedReport2 = null;
    if (this.isCredit) {
      this.webApi.getCreditReports(companyId).subscribe(
        reports => {
          this.setReports(reports, selectReport1, selectReport2);
        },
        error => (this.errorMessage = error)
      );
    } //Equity
    else {
      this.webApi.getReports(companyId).subscribe(
        reports => {
          this.setReports(reports, selectReport1, selectReport2);
        },
        error => (this.errorMessage = error)
      );
    }
  }

  setReports(
    reports: App.WebApi.ReportDisplay[],
    selectReport1: number,
    selectReport2: number
  ) {
    this.reports = reports;
    if (reports) {
      reports.forEach(x => {
        if (x.RatioId == selectReport1 || x.RatioId == selectReport2) {
          x.isActive = true;
          if (this.selectedReport1 == null) this.selectedReport1 = x;
          else this.selectedReport2 = x;
        }
      });
    }
  }

  updateReport() {
    this.sharePrice.hasSelectedPrice(this.isCredit);
    this.noFileSelected = (this.selectedFile == null);

    if (this.sharePrice.noSharePriceSelected || this.sharePrice.invalidNumber || this.noFileSelected)
      return;

    if (this.sharePrice.isManualPrice()) {
      this.errorMessage = null;
      this.sharePrice.saveIntradayPrice(this.selectedCompany.id).subscribe(
        (data) => {},
        error => {
          console.log("Error saving the price.");
          this.errorMessage = "Couldn’t save the price, please try again";
        },
        () => {
          console.log("Completed saving the price.");
          this.generatefile();
        }
      );
    } else if (
      this.sharePrice.isLastPrice() ||
      this.sharePrice.isPreliminaryPrice()
    ) {
      this.errorMessage = null;
      this.sharePrice
        .updateIntradayPriceFormulas(this.selectedCompany.id)
        .subscribe(
          data => {},
          error => {
            console.log("Error updating price formulas.");
            this.errorMessage =
              "Couldn’t update price formulas, please try again";
          },
          () => {
            console.log("Completed updating price formulas.");
            this.generatefile();
          }
        );
    } else {
      this.generatefile();
    }
  }

  sharePriceSelectChange() {
    this.sharePrice.hasSelectedPrice(this.isCredit);
  }

  generatefile() {
    this.oneliner = this.getOneliner();

    let model: App.WebApi.CreateDocumentRequest = {
      CompanyId: this.originalSettings.CompanyId,
      ReportReason: this.selectedReportReason.Value,
      ReportType: this.selectedReportType.Value,
      SentToCompany: this.sentToCompany == "true",
      MandateToIssueBonds: this.mandateToIssueBonds == "true",
      AdHocReport: this.adHocReport == "true",
      SharePriceStatus: this.isCredit
        ? 0
        : this.sharePrice.selectedSharePrice.type,
      SharePriceDate: this.isCredit
        ? new Date().toSweString()
        : new Date(this.sharePrice.selectedSharePrice.date.getUTCFullYear(),this.sharePrice.selectedSharePrice.date.getUTCMonth(),this.sharePrice.selectedSharePrice.date.getUTCDate(),this.sharePrice.selectedSharePrice.date.getUTCHours(),
        this.sharePrice.selectedSharePrice.date.getUTCMinutes(),this.sharePrice.selectedSharePrice.date.getUTCSeconds()).toSweString(),
      TemplateCreationEngine: this.selectedTemplateEngine.Value
    };
    if (this.selectedReport1 != null) {
      model.ReportId1 = this.selectedReport1.RatioId;
      model.ReportPeriodYear = this.selectedReport1.ReportPeriodYear;
      model.ReportPeriodQuartal = this.selectedReport1.ReportPeriodQuartal;
      model.ReportQuarterExist = this.selectedReport1.ReportQuarterExist;
      if (this.selectedReport2 != null)
        model.ReportId2 = this.selectedReport2.RatioId;
    }
    this.downloadfile(model);
  }

  downloadfile(data: App.WebApi.CreateDocumentRequest) {
    this.isCreatingReport = true;
    this.errorMessage = null;
    this.webApi.updateDocument(this.selectedFile, data).subscribe(
      data => {
        this.globalData.saveData(data.blob, data.fileName);
      }, //console.log(data),
      error => {
        console.log("Error downloading the file.");
        this.isCreatingReport = false;
        this.errorMessage = error;
      },
      () => {
        console.log("Completed file download.");
        this.isCreatingReport = false;
      }
    );
  }

  //Samma som för att skapa doc -> Flytta till gemensam fil
  getOneliner(): string {
    return this.globalData.getOneliner();
  }
}
