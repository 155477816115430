import { Component, OnInit, Input } from '@angular/core';
import { Report } from './report-information.model';

@Component({
  selector: 'report-information',
  templateUrl: './report-information.component.html',
  styleUrls: ['./report-information.component.css']
})
export class ReportInformationComponent implements OnInit
{
  @Input() report: Report;
  @Input() reportNumber: number;
  @Input() hasChanged: string;
  @Input() isCredit: boolean;
  constructor() { }

  ngOnInit()
  {
  }

}

