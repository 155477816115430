import { AuthorizeService } from './authorize.service';
import { mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./authorize.service";
export class AuthorizeInterceptor {
    constructor(authorize) {
        this.authorize = authorize;
    }
    intercept(req, next) {
        return this.authorize.getAccessToken()
            .pipe(mergeMap(token => this.processRequestWithToken(token, req, next)));
    }
    // Checks if there is an access_token available in the authorize service
    // and adds it to the request in case it's targeted at the same origin as the
    // single page application.
    processRequestWithToken(token, req, next) {
        if (token && token.length) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(req);
    }
}
AuthorizeInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizeInterceptor_Factory() { return new AuthorizeInterceptor(i0.ɵɵinject(i1.AuthorizeService)); }, token: AuthorizeInterceptor, providedIn: "root" });
